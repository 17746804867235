import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { AppDialogProps } from './types';
import AppButton from '../../../components/forms/AppButton';
import { Check, Close } from '@mui/icons-material';

import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';

const AppDialog: React.FC<AppDialogProps> = ({ open, onClose, message, severity, confirmActionText, cancelActionText, type = 'confirm', title = "Confirmação", invert=false }) => {
  
  const getIcon = () => {
    switch (severity) {
      case "success":
        return <CheckCircleIcon sx={{ color: "#4caf50", fontSize: 48 }} />;
      case "warning":
        return <WarningIcon sx={{ color: "#ff9800", fontSize: 48 }} />;
      case "error":
        return <ErrorIcon sx={{ color: "#f44336", fontSize: 48 }} />;
      case "info":
        return <InfoIcon sx={{ color: "#808080", fontSize: 48 }} />;
      default:
        return <InfoIcon sx={{ color: "#808080", fontSize: 48 }} />;
    }
  }

  return (
    <Dialog sx={{
      ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
        borderRadius: 4,
      },

      }} 
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClose(false);
        }
      }}
      open={open} 
      onClose={_ => onClose(false)}
      >
      <DialogContent sx={{ maxWidth: "500px"}} >
         <Box sx={{display:'flex', justifyContent:"center"}}>
          {getIcon()}
        </Box>
        <Box sx={{display:'flex', justifyContent:"center", mb:1, mt:1}}>
          <strong>{title}</strong>
        </Box>
        <DialogContentText>
          <Box sx={{display:'flex', justifyContent:"center", textAlign:"center" } }>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Box>
        </DialogContentText>
        
      </DialogContent>
      {
      (type !== 'loading' && !invert) && 
        <DialogActions sx={{justifyContent: "end", padding: 2, pt:0}}>
          {type === 'confirm' && (
           <AppButton startIcon={<Close />} sx= {{ color: "#808080", borderColor: "#808080" }} variant='outlined' onClick={() => onClose(false)} label={cancelActionText} /> 
          )}
          <AppButton startIcon={<Check />}  variant='contained' onClick={() => onClose(true)} label={type === 'confirm' ? confirmActionText : 'Ok'} />
        </DialogActions>
      }

      { (type !== 'loading' && invert) &&
        <DialogActions sx={{justifyContent: "end", padding: 2}}>
          <AppButton startIcon={<Check />} variant='contained' onClick={() => onClose(true)} label={type === 'confirm' ? confirmActionText : 'Ok'} />
          {
            type === 'confirm' && 
            <AppButton startIcon={<Close />} sx= {{ color: "#808080", borderColor: "#808080" }}  variant='outlined' onClick={() => onClose(false)} label={cancelActionText} /> 
          }
        </DialogActions>
      }
    </Dialog>
  );
};

export default AppDialog;
