import { createSvgIcon } from '@mui/material';


const AppWalkingIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M16.5 4C14.578125 4 13 5.578125 13 7.5C13 9.421875 14.578125 11 16.5 11C18.421875 11 20 9.421875 20 7.5C20 5.578125 18.421875 4 16.5 4 Z M 16.5 6C17.339844 6 18 6.660156 18 7.5C18 8.339844 17.339844 9 16.5 9C15.660156 9 15 8.339844 15 7.5C15 6.660156 15.660156 6 16.5 6 Z M 12.8125 11.25C12.296875 11.292969 11.816406 11.539063 11.46875 11.9375L9.65625 14.03125C9.417969 14.304688 9.25 14.640625 9.1875 15L8.5 18.875L10.46875 19.21875L11.15625 15.34375L12.96875 13.25L13 13.25L13.78125 13.40625L12.59375 18.78125C12.472656 19.339844 12.601563 19.914063 12.9375 20.375L18.46875 28L20.9375 28L14.5625 19.1875L15.75 13.78125L16.21875 13.875L16.96875 16.5C17.144531 17.101563 17.597656 17.589844 18.1875 17.8125L21.71875 19.15625L22.40625 17.28125L18.90625 15.9375L18.125 13.3125C17.917969 12.589844 17.332031 12.046875 16.59375 11.90625L13.34375 11.28125C13.171875 11.25 12.984375 11.234375 12.8125 11.25 Z M 12.46875 22.03125L11.96875 23.96875L9.0625 28L11.53125 28L13.84375 24.84375L13.96875 24.25Z" />
  </svg>,
  'AppWalking'
);

export default AppWalkingIcon;