import { createSvgIcon } from '@mui/material';


const AppMuscleIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M19.125 3.9375L18.78125 4.03125C18.78125 4.03125 17.878906 4.226563 16.96875 4.78125C16.058594 5.335938 15 6.402344 15 8C15 9.597656 16.058594 10.664063 16.96875 11.21875C17.390625 11.476563 17.484375 11.445313 17.8125 11.5625C17.457031 12.539063 16.828125 14.914063 17.375 18.25C16.597656 16.914063 15.152344 16 13.5 16C12.777344 16 12.117188 16.230469 11.375 16.53125C10.632813 16.832031 9.847656 17.238281 9.125 17.625C7.683594 18.394531 6.46875 19.15625 6.46875 19.15625L6 19.4375L6 25.625L6.5625 25.90625C6.5625 25.90625 10.761719 28 16 28C21.238281 28 25.4375 25.90625 25.4375 25.90625L26 25.625L26 25C26 20 25.441406 15.65625 24.78125 12.4375C24.453125 10.828125 24.117188 9.511719 23.78125 8.5C23.445313 7.488281 23.25 6.894531 22.75 6.34375C22.175781 5.707031 21.367188 5.1875 20.6875 4.78125C20.007813 4.375 19.4375 4.09375 19.4375 4.09375 Z M 18.90625 6.09375C19.097656 6.191406 19.265625 6.246094 19.6875 6.5C20.296875 6.863281 20.992188 7.371094 21.25 7.65625C21.167969 7.566406 21.570313 8.214844 21.875 9.125C22.179688 10.035156 22.527344 11.300781 22.84375 12.84375C23.445313 15.773438 23.917969 19.769531 23.96875 24.3125C23.210938 24.65625 20.152344 26 16 26C11.820313 26 8.730469 24.644531 8 24.3125L8 20.5625C8.324219 20.359375 8.953125 19.96875 10.0625 19.375C10.746094 19.011719 11.484375 18.667969 12.125 18.40625C12.765625 18.144531 13.34375 18 13.5 18C14.769531 18 15.792969 18.941406 15.96875 20.15625L16.09375 21L20.15625 21L19.65625 19.65625C18.191406 15.636719 19.90625 11.375 19.90625 11.375L20.375 10.28125L19.21875 10.03125C19.21875 10.03125 18.621094 9.890625 18.03125 9.53125C17.441406 9.171875 17 8.742188 17 8C17 7.257813 17.441406 6.828125 18.03125 6.46875C18.464844 6.203125 18.703125 6.152344 18.90625 6.09375Z" />
  </svg>,
  'AppMuscle'
);

export default AppMuscleIcon;