import { Box, FormLabel, Slider } from '@mui/material'
import React from 'react'


export interface AppSliderProps {
  name: string;
  label: string;
  value?: number;
  min?: number;
  max?: number;
  onChange?: (event: Event, value: number | number[]) => void;
}


const AppSlider: React.FC<AppSliderProps> = (props) => {
  return (
    <Box>
      <FormLabel id={`row-slider-${props.name}`}>{props.label}</FormLabel>
      <Slider
          name= {props.name}
          aria-label= {props.label}
          defaultValue={props.value || 0}
          valueLabelDisplay="auto"
          onChange={props.onChange}
          shiftStep={1}
          step={1}
          marks
          min={props.min || 0}
          max={props.max || 10}
        />
    </Box>
  )
}


export default AppSlider;