import { Box, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react"

export interface InfoPatientProps {
    title: string,
    data: string,
    sx?: SxProps<Theme>;
}

const InfoPatient: FC<InfoPatientProps> = (props) => {

    const { title, data, sx } = props;
    return (
        <Box sx={{ minWidth: "100px", ...sx }}>
            <Typography component="h5" sx={{ fontWeight: "bold", color: "#242121", fontSize: "14px" }}>{title}</Typography>
            <Typography component="h5" sx={{ color: "#666666", fontSize: "12px", mt: 1 }}>{data}</Typography>
        </Box>
    );
}

export default InfoPatient;