import { FC, useCallback } from "react";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import { SectionProps } from "./interfaces/SectionProps";
import { Grid } from "@mui/material";
import AppChipSelectModal from "../../../../components/forms/AppChipSelectModal";
import AppTextField from "../../../../components/forms/AppTextField";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import { PathologiesData } from "../../Stores/AnamnesisStore/types.d";
import { useAnamnesisStore } from "../../Stores/AnamnesisStore/AnamnesisStore";


const PathologiesSection: FC<SectionProps> = ({ complete }) => {

  const store = useAnamnesisStore(state => state.data.pathologiesDetail);
  // const setValidator = useAnamnesisStore(state => state.setValidator);

  // setValidator(yumConfig);

  const setPathologiesData = useAnamnesisStore(state => state.setData);


  const data = store;

  const setData = useCallback((fieldName: keyof PathologiesData, value: any) => {
    setPathologiesData( "pathologiesDetail", { ...data, [fieldName]: value });
  }, [data, setPathologiesData]);

  const options = [
    "Ansiedade",
    "Anemia",
    "Câncer",
    "Problemas Cardíacos",
    "Problemas Circulatórios",
    "Colite",
    'Depressão',
    "Diabetes",
    "Dislipidemia",
    "Dor de Cabeça",
    "Dor de Estômago",
    "Endócrino",
    "Gastrite",
    "Irritabilidade",
    "Herpes",
    "Hepatite",
    "Hipertireoidismo",
    "Hipotireoidismo ",
    "Hipoglicemia",
    "Hipertensão",
    "Osteoporose",
    "Problemas Renais",
    "Rinite",
    "Sinusite",
    "Obesidade"
  ];




  const sortedOptions = options.sort((a, b) => a.localeCompare(b));

  return (
    <AppCheckAccordion
      title="Patologias"
      complete={complete}
    >
      <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>
        <Grid item xs={12} sm={12} md={12}>
          <AppChipSelectModal
            label="Patologias do Paciente"
            optionsLabel="Patologias comuns"
            chips={sortedOptions}
            selectedChips={data.pathologiesList}
            setSelectedChips={(data) => setData('pathologiesList', data)}
          />

        </Grid>

        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            name="medicines"
            label="Medicação Contínua"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
            value={data.medicines}
            onChange={(e) => setData('medicines', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            name="exams"
            label="Exames"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
            value={data.exams}
            onChange={(e) => setData('exams', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            name="pathologiesFamilyHistory"
            label="Histórico Familiar"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
            value={data.pathologiesFamilyHistory}
            onChange={(e) => setData('pathologiesFamilyHistory', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            name="pathologies-obs"
            label="Observações Gerais"
            labelType={LabelType.INLINE}
            multiline rows={4}
            value={data.notes}
            onChange={(e) => setData('notes', e.target.value)}
          />
        </Grid>


      </Grid>
    </AppCheckAccordion>
  );

}

export default PathologiesSection;