import { Accordion, AccordionDetails, AccordionSummary, SxProps, Theme, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { ReactElement } from "react";

export interface AppAcordionProps {
    children?: React.ReactNode | React.ReactNode[];
    title: string;
    titleIcon?: ReactElement<any, any>,
    sx?: SxProps<Theme>;
    defaultExpanded: boolean
}

const AppAcordion: React.FC<AppAcordionProps> = (props) => {

    const { title, children, sx, defaultExpanded, titleIcon } = props;

    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            sx={{  
                boxShadow: "none",
                ...sx}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ display: "flex", alignItems: "center", gap: "8px" }} >
                {titleIcon && titleIcon}
                <Typography variant="h5" component="h3" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: "16px" }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );

}

export default AppAcordion;