import { Gender } from "../../../../../../domain/interfaces/Patient";
import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { AnthropometricCaculateType } from "../types";
import SkinfoldProtocolServiceBase from "./SkinfoldProtocolServiceBase";
import { ISkinfoldProtocolCalculate } from "./types";

export default class ThreeSkinfoldJacksonAndPollockAndWardService extends SkinfoldProtocolServiceBase implements ISkinfoldProtocolCalculate {

    public skinfoldProtocolType: SkinfoldProtocolType = SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard;

    constructor(anthopometricCalculate: AnthropometricCaculateType) {
        super(anthopometricCalculate);
        this.sumOfSkinfold = this.getSumOfSkinFolds();

        this.isValid = this.isAllMeasures() && this.isWeigthAndHeight();
    }  
    
    public calculate(): BodyComposition {
        if(!this.isValid) {
            return this.getDefaultBodyComposition();
        }

        let bodyDensity: number;         
            
        if (this.gender === Gender.Male) {
            bodyDensity = this.maleCaculate();
        } else {
            bodyDensity = this.femaleCaculate();
        }

        return this.getBodyComposition(bodyDensity);
    }

    private maleCaculate(): number {
        const bodyDensity = 1.10938 - (0.0008267 * this.sumOfSkinfold) 
            + 0.0000016 * Math.pow(this.sumOfSkinfold, 2) 
            - (0.0002574 * this.age);
        return bodyDensity;
    }

    private femaleCaculate(): number {
        const bodyDensity = 1.0994921 - (0.0009929 * this.sumOfSkinfold) 
            + 0.0000023 * Math.pow(this.sumOfSkinfold, 2) 
            - (0.0001395 * this.age);
        return bodyDensity;
    }

    public isAllMeasures(): boolean {
        return this.isAllMeasuresWithType(this.skinfoldProtocolType);
    }

}