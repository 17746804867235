import axios from "axios";

export interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export default class AddressService {
  
  public static async getAddressByPostalCode(postalCode: string): Promise<Address | null> {
     try{
       const response = await axios.get(`https://viacep.com.br/ws/${postalCode}/json/`);
       return response.data;

     }catch(err){
       return null;
     }
  }

  public static getCountries(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      axios.get(`https://restcountries.com/v3.1/all`)
        .then((response) => {
          const countries = response.data.map((country: any) => country.name.common) as string[];
          resolve(countries);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async getStatesByCountry(countryName: string): Promise<string[]> {
    const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
    const states = response.data.map((state: any) => state.sigla) as string[];
    return states.sort((a, b) => a.localeCompare(b));
  }

  public static async getCitiesByState(stateName: string): Promise<string[]> {
    
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateName}/municipios`);
      
      const cities = response.data.map((city: any) => city.nome) as string[];  

      return cities;
    
  }
}



