import React, { useMemo } from 'react';
import { Box, InputLabel, OutlinedInputProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactInputMask from 'react-input-mask';
import { LabelType } from './enums/LabelType';
import AppInputError from './components/InputErro';

interface FormTextFieldProps extends OutlinedInputProps {
  name: string,
  id?: string,
  label?: string,
  value?: string | number,
  labelType?: LabelType,
  type?: string,
  mask?: string;
  onRawValueChange?: (value: string) => void;
  setFormErros?: any;
  formErros?: any;
  errorMessage?: string;
  isNumberMask?: boolean;
  optional?: boolean;
}


const AppMaskedTextField: React.FC<FormTextFieldProps> = (props) => {

  let {
    name,
    mask,
    sx,
    label,
    labelType,
    errorMessage,
    type,
    id,
    isNumberMask,
    prefix,
    placeholder,
    disabled,
    value,
    fullWidth,
    optional,
    readOnly,
    ...rest } = props;

  const theme = useTheme();

  let hasError = errorMessage !== undefined && errorMessage !== null && errorMessage !== "";
  const labelColor = disabled || optional ? theme.palette.text.disabled : theme.palette.text.primary;

  const inputStyle = useMemo(() => {
    return {
      paddingLeft: "4px",
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none'
    }
  }, []);

  const boxStyle = useMemo(() => ({
    pointerEvents: disabled ? "none" : "auto",
    border: `1px solid ${hasError ? "#E17373" : "#ccc"}`,
    position: "relative",
    padding: "10px",
    display: "inline-flex",
    borderRadius: 3,
    width: fullWidth ? "100%" : "auto",
    color: theme.palette.text.primary,
    "&:focus-within": {
      border: `1px solid ${hasError ? "#E17373" : theme.palette.primary.main}!important`,
      outline: `1px solid ${hasError ? "#E17373" : theme.palette.primary.main}`,
      color: `${theme.palette.primary.main} !important`,
    },
    "&:hover": {
      border: `1px solid ${hasError ? "#E17373" : "#000"}`,
    }
  }), [hasError, theme.palette.primary.main, disabled, fullWidth]);

  if (!labelType) {
    labelType = LabelType.FLOATING;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {labelType === LabelType.INLINE &&
        <InputLabel sx={{ m: "6px 0 0", fontWeight: "medium", fontSize: "12px" }}>
          {label}
        </InputLabel>
      }
      <Box sx={boxStyle}>
        {labelType === LabelType.FLOATING &&
          <Typography sx={{
            position: "absolute",
            top: "-5px",
            fontSize: "10px",
            fontFamily: theme.typography.fontFamily,
            backgroundColor: "#fff",
            padding: "0 4px",
            color: labelColor,
            fontWeight: "bold"
          }}>
            {label}
          </Typography>
        }
        <ReactInputMask
          id={id}
          mask={mask ?? ""}
          autoComplete='off'
          value={value}
          onChange={(e) => {
            if (rest.onChange) {
              rest.onChange(e);
            }

            if (props.onRawValueChange) {
              props.onRawValueChange(e.target.value.replace(/[^0-9]/g, ''));
            }

          }}
          style={inputStyle}
        />
      </Box>
      <AppInputError error={errorMessage} />
    </Box>
  );
}

export default AppMaskedTextField;