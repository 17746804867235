import { Typography, Box, ThemeProvider, useTheme } from '@mui/material';


import logo from '../../assets/img/nutrifit-logo.png';

interface Props {
    pagePicture: string;
    title?: string;
    subtitle?: string;
    pageOrientation: string;
    children?: React.ReactNode;
}


function AppFormContainer ({pagePicture, title, pageOrientation, children, subtitle}: Props) {

    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>

            <Box sx={{ backgroundColor: theme.palette.primary.main, minHeight:"100vh", display:"flex", alignItems:"center", justifyContent:"center", '@media (max-width: 780px)':  { flexDirection: "column", height: "unset" }}}>
                <Box sx={ { width: "50vw", '@media (max-width: 780px)':  { display: "none" } } } > 
                    <Box sx={{  display:"flex", alignItems:"center", justifyContent:"center", padding: "32px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", maxWidth: "500px" }} >
                            {title &&
                                <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.contrastText, lineHeight: "4rem", fontSize: "3rem", textAlign: "center", margin: "30px auto auto" }} component="h1"> 
                                    {title}
                                </Typography>
                            }
                            
                            <img src={pagePicture} alt="Nutrifit"
                                style={{ maxWidth: "300px" }}
                            />                        
                        </Box>
                    </Box>        
                </Box>
                <Box sx={{ backgroundColor: theme.palette.background.paper,  minHeight:"100vh", width: "50vw", display: "flex", flexDirection: "column", alignItems:"center", justifyContent:"center", '@media (max-width: 780px)':  {  width: "100vw" }}}>
                    <Box sx={{  maxWidth: "500px !important", padding: "32px",  '@media (min-width: 1024px)':  {  minWidth: "500px" }}}>

                        <img src={logo} alt="Nutrifit"
                            style={{ width: "250px", 
                            display: "block", 
                            margin:"24px auto" }}
                        />  

                        {subtitle &&
                            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.main, lineHeight: "3rem", fontSize: "2rem", textAlign: "center", mb: 2, mt: 4 }} component="h1"> 
                                {subtitle}
                            </Typography>
                        }

                        {title &&
                            <Typography sx={{ display: "none", fontWeight: "bold", color: theme.palette.primary.main, lineHeight: "3rem", fontSize: "2rem", textAlign: "center", margin: "30px auto auto", mb: "30px", '@media (max-width: 780px)':  { display: "block" } }} component="h1"> 
                                {title}
                            </Typography> 
                        }
                            
                        <Typography sx={{ textAlign: "center", fontSize:"1rem", '@media (max-width: 780px)': { fontSize:"0.7rem" } }} > 
                            {pageOrientation} 
                        </Typography>
                        
                        <>
                            {children}
                        </>
                    </Box>
                </Box>

            </Box>
        </ThemeProvider>
    );
}

export default AppFormContainer;
