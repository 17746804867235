import React, { ChangeEvent } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, FormControlLabel, Switch, Typography, debounce } from "@mui/material";
import { Add } from "@mui/icons-material";

import AppMainLayout from "../../components/layout/AppMainLayout";
import AppCard from "../../components/layout/AppCard";
import AppMainHeader from "../../components/layout/AppMainHeader";
import AppModal from "../../components/layout/AppModal";
import AppTable, { AppTableColumn, AppTablePagination, AppTableRowData } from "../../components/layout/AppTable/AppTable";
import PatientService, { GetPatientsByEmailOrName } from "../../services/patient.service";
import { useAuth } from "../../hooks/core/AuthProvider";
import { Patient } from "../../domain/interfaces/Patient";
import NewPatientForm from "./components/NewPatientForm";
import AppButton from "../../components/forms/AppButton";
import AppTextField from "../../components/forms/AppTextField";
import AppSearchIcon from "../../components/icons/AppSearchIcon";
import AppLoading from "../../components/utils/AppLoading";
import { ROUTE_ACTIONS } from "../../constants/routes";


const MyPatientsPage: React.FC = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = React.useState([] as AppTableRowData[]);
  const [isFiltered, setIsFiltered] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOnlyActive, setIsOnlyActive] = React.useState(true);
  const [valueSearch, setValueSearch] = React.useState("" as string);
  const { user } = useAuth();
  const [modalNewPatientIsOpen, setModalNewPatientIsOpen] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20],
    count: 0
  } as AppTablePagination);

  const columns: AppTableColumn[] = [
    { prop: 'name', label: 'Nome', maxWidth: "300px" },
    { prop: 'email', label: 'E-mail', maxWidth: "300px" },
    { prop: 'ativo', label: 'Ativo', maxWidth: "100px" },
    { prop: 'comments', label: 'Comentários', align: 'center' }
  ];

  const navigateToViewPatient = (patient: Patient) => {
    navigate(`/meus-pacientes/${patient.id}/${ROUTE_ACTIONS.VIEW}`);
  }

  const handleResponse = (response: any) => {
    if (response.status === 204) {
      setPatients([]);
      setIsFiltered(false);
    }

    if (response.status === 200) {
      setIsFiltered(true);
      const { count, items } = response.data;
      const patients = items as Patient[];

      setPagination({
        ...pagination,
        count: count,
      });

      const patientsData = patients.map(patient => {
        return {
          name: { value: patient.name, onClick: () => navigateToViewPatient(patient) },
          email: { value: patient.email, onClick: () => navigateToViewPatient(patient) },
          ativo: { value: patient.isActive ? "Ativo" : "Inativo" },
          comments: { value: patient.observation }
        } as AppTableRowData;
      });

      setPatients(patientsData);
    }
  }

  const getMyPatients = async () => {
    setIsLoading(true);
    const { rowsPerPage, page } = pagination;
    const offset = page * rowsPerPage;

    const response = await PatientService.getPatientsByEmailOrName({
      email: valueSearch,
      name: valueSearch,
      nutricionistId: user.id,
      isOnlyActive: isOnlyActive,
      rowsPerPage: rowsPerPage,
      offset: offset
    } as GetPatientsByEmailOrName);

    handleResponse(response);
    setIsLoading(false);
  }

  const handleGetPatient = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setValueSearch(value);
  }

  React.useEffect(() => {
    getMyPatients();
  }, []);

  React.useEffect(() => {
    getMyPatients();
  }, [pagination?.rowsPerPage, pagination?.page, isOnlyActive, valueSearch]);

  return (
    <AppMainLayout>
      <AppLoading isLoading={isLoading} />
      <AppMainHeader hasBreadCrumbs={false} />

      <AppCard>
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          Meus Pacientes
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", mb: 2, mt: 2, gap: 2 }}>
          <AppTextField
            sx={{
              backgroundColor: "#f5f5f5",
              width: "320px",
              "&:hover": {
                outline: "none",
                borderColor: "red",
              },
            }}
            name="search"
            type="text"
            autoFocus={false}
            startAdornment={<AppSearchIcon sx={{ mr: 0 }} />}
            placeholder="Pesquisar pacientes"
            onChange={debounce((e) => handleGetPatient(e), 1000)}
            onBlur={debounce((e) => handleGetPatient(e), 1000)}
            fullWidth={false}
          />
          <AppButton
            variant="contained"
            startIcon={<Add />}
            label="Adicionar Paciente"
            fullWidth={false}
            onClick={() => setModalNewPatientIsOpen(true)}
          />
        </Box>

        <FormControlLabel control={
          <Switch
            defaultChecked={true}
            value={isOnlyActive}
            onChange={(e) => {
              setIsOnlyActive(!isOnlyActive);
            }}
          />}
          sx={{ mb: 2 }}
          label="Somente Ativos" />

        {isFiltered &&
           <AppTable rows={patients} columns={columns} pagination={pagination} setPagination={setPagination} />
        }

        <AppModal isOpen={modalNewPatientIsOpen} handleClose={() => setModalNewPatientIsOpen(false)} title="Novo Paciente">
          <NewPatientForm />
        </AppModal>
        {!isFiltered &&
          <Box
            sx={{
              backgroundColor: "#F2F2F2",
              borderRadius: "12px",
              padding: "12px",
              mt: "32px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold", lineHeight: "22px" }}>
              Não existem registros para o filtro escolhido. <br /> Escolha um novo filtro para prosseguir com a busca.
            </Typography>
          </Box>
        }
      </AppCard>
    </AppMainLayout>
  );
};

export default MyPatientsPage;
