import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AppBoltIcon: React.FC<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">                
                <path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z" fill="rgb(239,239,63)"/>
            </svg>
        </SvgIcon>
    )
}

export default AppBoltIcon;