import { Gender } from "../../../../../../domain/interfaces/Patient";
import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { AnthropometricCaculateType } from "../types";
import SkinfoldProtocolServiceBase from "./SkinfoldProtocolServiceBase";
import { ISkinfoldProtocolCalculate } from "./types";

export default class SevenSkinfoldJacksonAndPollockAndWardService extends SkinfoldProtocolServiceBase implements ISkinfoldProtocolCalculate {

    public skinfoldProtocolType: SkinfoldProtocolType = SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard;

    constructor(anthopometricCalculate: AnthropometricCaculateType) {
        super(anthopometricCalculate);
        this.sumOfSkinfold = this.getSumOfSkinFolds();

        this.isValid = this.isAllMeasures() && this.isWeigthAndHeight();
    }  
    
    public calculate(): BodyComposition {
        let bodyDensity: number;  

        if(!this.isValid) {
            return this.getDefaultBodyComposition();
        }
            
        if (this.gender === Gender.Male) {
            bodyDensity = this.maleCaculate();
        } else {
            bodyDensity = this.femaleCaculate();
        }

        return this.getBodyComposition(bodyDensity);
    }

    private maleCaculate(): number {
        const bodyDensity = 1.112 - 0.00043499 * this.sumOfSkinfold
            + 0.00000055 * Math.pow(this.sumOfSkinfold, 2)
            - 0.00028826 * this.age;
        return bodyDensity;
    }

    private femaleCaculate(): number {
        const bodyDensity =  1.097 - 0.00046971 * this.sumOfSkinfold
            + 0.00000056 * Math.pow(this.sumOfSkinfold, 2)
            - 0.00012828 * this.age;
        return bodyDensity;
    }

    public isAllMeasures(): boolean {
        return this.isAllMeasuresWithType(this.skinfoldProtocolType);
    }

}