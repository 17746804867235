import { createSvgIcon } from '@mui/material';


const AppSmellingIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" >
    <path d="M28.6,127h70.8c13.7,0,25.7-10,27.4-23.6c1.3-10.2-3.3-20.1-11.7-25.8c-1.4-1-3.4-0.5-4.3,1c-0.8,1.4-0.3,3.2,1,4.1 c5.7,3.9,9.1,10.4,9.1,17.3c0,9.1-5.9,16.9-14,19.8c0-9.2-5.8-17.3-14.5-20.3c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.1,0-0.2,0-0.4 c0-1.9-0.2-3.8-0.6-5.7L77,8.5c-0.3-1.8-2.2-3-4.1-2.3c-1.3,0.5-2.1,2-1.8,3.4l14.4,84.6c0,0,0,0.1,0,0.1l0.1,0.3 C85.8,96,86,97.5,86,99c0,12.1-9.9,22-22,22c-7.3,0-13.8-3.5-17.8-9c-4.5-6.2-13.5-7.1-18.6-1.4c-1.7,1.8-2.8,3.8-2.8,5.9v4.2 C14.8,119.2,7,110.5,7,100c0-6.9,3.4-13.4,9.1-17.3c1.3-0.9,1.8-2.7,1-4.1c-0.9-1.5-2.8-1.9-4.3-1c-8.4,5.7-13,15.5-11.7,25.8 C2.9,117,14.9,127,28.6,127z" />
  </svg>,
  'AppSmelling'
);

export default AppSmellingIcon;