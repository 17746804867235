import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Patient } from '../../../../domain/interfaces/Patient';
import { PatientStore } from './types.d';

export const usePatientStore = create<PatientStore>()(
  persist(
    (set) => ({
      patient: {} as Patient,
      setPatient: (patient: Patient) => {
        set((state) => ({
          ...state,
          patient,
        }));
      },
    }),
    {
      name: 'app-nutrifit-patient-store', 
      getStorage: () => localStorage,
    }
  )
);