import config from "./config.json";

import { BrowserRouter as Router } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/pt-br";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import theme from "./styles/themes";

import { ThemeProvider } from "@mui/material/styles";
import { gapi } from "gapi-script";
import AppProvider from "./hooks/core/AppProvider";
import Routes from "./routes";
import PatientRoutes from "./routes/PatientRoutes";

function App() {
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: config.GOOGLE_CLIENT_ID,
      plugin_name: "chat",
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
        <AppProvider>
          <Router>
            <Routes />
            <PatientRoutes />
          </Router>
        </AppProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
