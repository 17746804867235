import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Typography, Box, Divider, Link, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import * as Yup from 'yup';
import { GoogleLogin } from 'react-google-login';
import config from '../../config.json';
import loginPicture from '../../assets/login.svg';
import googleIcon from '../../assets/google.svg';
import AppFormContainer from '../../components/layout/AppFormContainer';
import AppButton from '../../components/forms/AppButton';
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import { LoginCredentials, useAuth } from '../../hooks/core/AuthProvider';
import AppErrorList from '../../components/forms/AppErrorList';
import AppLoading from '../../components/utils/AppLoading';
import { ROUTES } from '../../constants/routes';
import FormService, { FormErrors } from '../../services/form.service';
import AppTextField from '../../components/forms/AppTextField';

const formSchema = Yup.object({
    password: Yup
        .string()
        .required('Obrigatório informar a senha.'),
    email: Yup
        .string()
        .email("O e-mail deve ser válido.")
        .required('Obrigatório informar o email.')
});

const LoginPage: React.FC = () => {

    const navigate = useNavigate();
    const { login, loginGoogle, confirmEmail } = useAuth();
    const [searchParams] = useSearchParams();
    const { addToast } = useAppToast();

    const [formData, setFormData] = useState({ email: "", password: "" } as LoginCredentials);
    const [errors, setErrors] = useState([] as string[]);
    const [formErrors, setFormErrors] = useState({} as FormErrors);
    const [isLoading, setIsLoading] = useState(false);

    const formService = new FormService(formData, setFormData, setErrors, setFormErrors);


    const confirmarEmailUsuario = () => {
        const token = searchParams.get("token") ?? undefined;
        const email = searchParams.get("email") ?? undefined;

        if (email) {
            setFormData({ ...formData, email });
        }

        if (email && token) {
            setIsLoading(true);
            confirmEmail({ email, token })
                .then(() => {
                    addToast({
                        title: "Email Confirmado",
                        description: `O e-mail ${email} foi confirmado com sucesso! Realize o login para ter acesso ao sistema!`,
                        type: "success"
                    })
                })
                .catch(err => {
                    addToast({
                        title: "Falha na Confirmação do E-mail",
                        description: `Houve uma falha na confirmação do e-mail ${email}. Por favor contate o suporte técnico para mais detalhes.`,
                        type: "error"
                    })
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id: name, value } = event.target;
        formService.setInputValue(name, value);
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        formService.cleanErrors();
        try {

            await formSchema.validate(formData, { abortEarly: false });

            await login({ email: formData.email, password: formData.password });

            addToast({
                title: `Bem vindo!`,
                description: "Login realizado com sucesso!",
                type: "success"
            });

            navigate(ROUTES.HOME);
        } catch (err) {

            formService.handleErros(err);
            setIsLoading(false);
        }
    };

    const googleLogin = async (response: any) => {

        formService.cleanErrors();
        setIsLoading(true);

        try {
            const { email, name } = response.profileObj;

            var retorno = await loginGoogle(response.tokenId);

            if (retorno === false) {
                setIsLoading(false);

                addToast({
                    title: "Ops!",
                    description: "Houve uma falha no login com o google, realize seu cadastro para que possamos prosseguir com o login!",
                    type: "info"
                });

                navigate(`${ROUTES.REGISTER}?nome=${name}&email=${email}`);
                return;
            }

            addToast({
                title: `Bem vindo, ${name}!`,
                description: "Login realizado com sucesso!",
                type: "success"
            });

            navigate(ROUTES.HOME);
        } catch (err) {
            formService.handleErros(err);
            setIsLoading(false);
        }
    };

    useEffect(() => { confirmarEmailUsuario() }, [searchParams]);

    return (

        <>
            <AppLoading isLoading={isLoading} />
            <AppFormContainer
                title='Bem-vindo(a) à Nutrifit!'
                pagePicture={loginPicture}
                pageOrientation='Faça login para continuar'
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{mt:1}}>
                        <Grid item md={12} xs={12}>
                            <AppTextField
                                id="email"
                                label="Seu E-mail:*"
                                name="email"
                                autoFocus
                                fullWidth
                                value={formData.email}
                                onChange={handleChange}
                                errorMessage={formErrors.email}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <AppTextField
                                name="password"
                                label="Senha:*"
                                type="password"
                                id="password"
                                fullWidth
                                autoComplete="current-senha"
                                value={formData.password}
                                onChange={handleChange}
                                errorMessage={formErrors.password}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <AppButton
                                fullWidth
                                startIcon={<LoginIcon />}
                                type="submit"
                                variant='contained'
                                label='Entrar'
                            />
                        </Grid>
                    </Grid>
                </form>

                <Divider sx={{ my: 2 }}>Ou</Divider>

                <Box sx={{ width: "100%" }}>
                    <GoogleLogin
                        clientId={config.GOOGLE_CLIENT_ID}
                        buttonText="Entre com o google"
                        onSuccess={googleLogin}
                        onFailure={googleLogin}
                        cookiePolicy={'single_host_origin'}
                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        render={renderProps => (
                            <Button
                                onClick={renderProps.onClick}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: "36px", cursor: 'pointer', backgroundColor: "#fff", padding: '8px', borderRadius: "8px", border: "1px solid #ccc", '&:hover': { backgroundColor: "#f9fafa" } }}
                            >
                                <img src={googleIcon} alt="Google" style={{ width: "24px", marginRight: "8px" }} />
                                <Typography sx={{ color: "#3b4045", fontSize: "12px !important", lineHeight: "24px" }}>Entre com o google</Typography>
                            </Button>
                        )}
                    />
                </Box>

                <AppErrorList errors={errors} />

                <Box sx={{ mt: "16px" }}>
                    <Link href={ROUTES.FORGOT_PASS} sx={{ lineHeight: "36px" }}>
                        Esqueceu sua senha?
                    </Link><br />

                    <Link href={ROUTES.REGISTER} sx={{ lineHeight: "36px" }}>
                        Não tem uma conta? Cadastre-se aqui.
                    </Link>
                </Box>

            </AppFormContainer>
        </>
    );
}

export default LoginPage;