import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { AnthropometricCaculateType } from "../types";
import SkinfoldProtocolServiceBase from "./SkinfoldProtocolServiceBase";
import { ISkinfoldProtocolCalculate } from "./types";

export default class ThreeSkinfoldGuedesService extends SkinfoldProtocolServiceBase implements ISkinfoldProtocolCalculate {

    public skinfoldProtocolType: SkinfoldProtocolType = SkinfoldProtocolType.ThreeSkinfoldGuedes;

    constructor(anthopometricCalculate: AnthropometricCaculateType) {
        super(anthopometricCalculate);
        this.sumOfSkinfold = this.getSumOfSkinFolds();

        this.isValid = this.isAllMeasures() && this.isWeigthAndHeight();
    }  
    
    public calculate(): BodyComposition {
        
        if(!this.isValid) {
            return this.getDefaultBodyComposition();
        }

        const bodyDensity = 1.17136 - 0.06706 * Math.log(this.sumOfSkinfold);

        return this.getBodyComposition(bodyDensity);
    }

    public isAllMeasures(): boolean {
        return this.isAllMeasuresWithType(this.skinfoldProtocolType);
    }

}