import { FC, useCallback, useEffect, useState } from 'react';
import AppAcordion from '../../../components/layout/AppAcordion';
import AddIcon from '@mui/icons-material/Add';
import { Box, TablePagination, Typography } from '@mui/material';
import CardInfo, { CardInfoMenu } from '../components/CardInfo';
import AppButton from '../../../components/forms/AppButton';
import { useNavigate } from 'react-router-dom';
import api from "../../../services/api";
import { Anthropometric } from '../Interations/Anthropometric/types/Anthropometric';
import AppWeigthScaleIcon from '../../../components/icons/AppWeigthScaleIcon';
import { Edit, Print, PictureAsPdf, Delete } from '@mui/icons-material';
import { useDialog } from '../../../hooks/core/AppDialog/AppDialogProvider';
import { useAppToast } from '../../../hooks/core/AppToastContextProvider';

export interface LastAnthropometricsSectionProps {
  patientId: string;
}

const LastAnamnesisSection: FC<LastAnthropometricsSectionProps> = ({ patientId }) => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { addToast } = useAppToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anthropometricsList, setAnthropometricsList] = useState([] as Anthropometric[]);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10],
    count: 0
  });

  const handleNavigate = useCallback((link: string, data: any) => {
    navigate(link || "", {
      state: data
    })
  }, [navigate]);

  const getAnthropometrics = useCallback(async (patientId: string, rowsPerPage: number, page: number) => {
    const offset = page * rowsPerPage;

    setIsLoading(true);
    const response = await api.get(`/${patientId}/anthropometrics?Limit=${rowsPerPage}&Offset=${offset}`)

    if(response.status === 204) {
      setAnthropometricsList([]);
    }

    if (response.status === 200) {
      var { items, count } = response.data;
      const list = items as Anthropometric[];

      setPagination(p => ({ ...p, rowsPerPage, page, count }))
      setAnthropometricsList(list);
    }
    setIsLoading(false);
  }, [setIsLoading, setPagination, setAnthropometricsList]);

  const handleRemove = useCallback(async (patientId: string, antropometricId: string) => {
    let confirm = await dialog.confirm({ title: "Confirma a exclusão desta antropometria?", message: "", invert: false });
    const { rowsPerPage, page } = pagination;
    if (!confirm) return;

    setIsLoading(true);

    const response = await api.delete(`/${patientId}/anthropometrics/${antropometricId}`);

    setIsLoading(false);

    if (response.status === 200) {
      addToast({
        title: "Antropometria excluida!",
        description: "Antropometria excluida com sucesso!",
        type: "success"
      });

      await getAnthropometrics(patientId, rowsPerPage, page);
      return;
    }

    addToast({
      title: "Falha na exclusão da antropometria!",
      description: "Houve uma falha na exclusão da antropometria!",
      type: "error"
    });

  }, [addToast, getAnthropometrics, pagination, dialog]);

  const menus = useCallback((patientId: string, anthropometric: Anthropometric): CardInfoMenu[] => {
    const link = `/meus-pacientes/${patientId}/antropometria/${anthropometric.id}`;
    return [
      {
        label: "Editar",
        icon: <Edit />,
        onClick: () => handleNavigate(link, anthropometric)
      },
      {
        label: "Excluir",
        icon: <Delete />,
        onClick: () => handleRemove(patientId, anthropometric.id)
      },
      {
        label: "Imprimir",
        icon: <Print />,
        onClick: () => handleNavigate(link, anthropometric)
      },
      {
        label: "PDF",
        icon: <PictureAsPdf />,
        onClick: () => handleNavigate(link, anthropometric)
      }
    ];
  }, [handleNavigate, handleRemove]);
  
  const handleChangePage = (event: unknown, newPage: number) => {
    if (!setPagination) return;

    setPagination((p) => ({
      ...p,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setPagination) return;

    setPagination((p) => ({
      ...p,
      rowsPerPage: parseInt(event.target.value)
    }));
  };

  useEffect(() => {
    getAnthropometrics(patientId, pagination.rowsPerPage, pagination.page);
  }, [patientId, getAnthropometrics, pagination.rowsPerPage, pagination.page]);

  return (
    <>
      <AppAcordion
        defaultExpanded={true}
        title="Últimas Antropometrias"
        sx={{ mt: 3, fontSize: "12px" }}
        titleIcon={<AppWeigthScaleIcon sx={{ fontSize: "22px", mr: 1 }} />}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1, p: "16px",
          borderRadius: "12px",
          backgroundColor: "#F2F2F2"
        }}>
          {!isLoading && anthropometricsList.map(
            anthropometric => {
              return <CardInfo
                link={`/meus-pacientes/${patientId}/antropometria/${anthropometric.id}`}
                data={anthropometric}
                key={anthropometric.id}
                title={`Antropometria realizada no dia ${new Date(anthropometric.createdDate).toLocaleString()}`}
                icon={<AppWeigthScaleIcon />}
                sx={{ fontSize: "18px", mr: 1 }}
                menus={menus(patientId, anthropometric)} />
            })
          }

          {(!isLoading && anthropometricsList.length > 0) && <>
            <TablePagination
              rowsPerPageOptions={pagination.rowsPerPageOptions}
              component="div"
              count={pagination.count}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
              }}

            />

          </>}

          {(!isLoading && anthropometricsList.length === 0) && <>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant='h6' sx={{ color: "text.secondary", mb: 2, fontWeight: "bold" }}>Nenhuma antropometria criada.</Typography>
              <AppButton label="Criar Nova Antropometria" variant="contained" startIcon={<AddIcon />} onClick={() => navigate(`/meus-pacientes/${patientId}/nova-antropometria`)} />

            </Box>

          </>}

          {isLoading && <>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

              <Typography variant="body1">Carregando...</Typography>

            </Box>
          </>}

        </Box>
      </AppAcordion>
    </>);
}

export default LastAnamnesisSection;