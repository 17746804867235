import { Patient } from "../../../../../../domain/interfaces/Patient";
import { PatientService } from "../../../../services/PatientService";
import { BodyCompositionType } from "../../enums/BodyCompositionType";
import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { Anthropometric } from "../../types/Anthropometric";
import BioimpedanceService from "../Bioimpedance/BioimpedanceService";
import { AnthropometricCaculateType, IAnthropometricProtocolService } from "../types";
import WeltmanProtocolService from "../WeltmanProtocol/WeltmanProtocolService";
import { skinfoldProtocolFactory } from "./skinfoldFactory";


const getAnthropometricCalculate = (anthropometricData: Anthropometric, patient: Patient): AnthropometricCaculateType => {
    const { bodyComposition, height, weight } = anthropometricData;
    const patientAge = PatientService.getPatientAge(patient);

    return {
        age: patientAge,
        data: bodyComposition,
        gender: patient.gender,
        height: Number(height),
        weight: Number(weight),
    } as AnthropometricCaculateType;

};

export function anthropometricServiceFactory(anthropometric: Anthropometric, patient: Patient): IAnthropometricProtocolService {
    const anthropometricCalculate = getAnthropometricCalculate(anthropometric, patient);
    const { bodyCompositionType } = anthropometricCalculate.data;

    switch (bodyCompositionType) {
        case BodyCompositionType.Bioimpedance:
            return new BioimpedanceService(anthropometric.bodyComposition);
        case BodyCompositionType.SkinfoldProtocol:
            const { protocol } = anthropometric.bodyComposition.skinfoldProtocol;
            const skinfoldProtocolType = protocol ?? SkinfoldProtocolType.FourSkinfoldDurninAndWomersley;
            return skinfoldProtocolFactory(skinfoldProtocolType, anthropometricCalculate);
        default:
            return new WeltmanProtocolService(anthropometricCalculate);
    }
}