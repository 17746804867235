import { Navigate, Routes as ReactDomRoutes, Route } from "react-router-dom";
import LoginPage from "../pages/Auth/LoginPage";
import RegisterPage from "../pages/Auth/RegisterPage";
import ForgotPassPage from "../pages/Auth/ForgotPassPage";
import ResetPassPage from "../pages/Auth/ResetPassPage";
import RegisterSuccessFeedbackPage from "../pages/Auth/RegisterSuccessFeedbackPage";
import ForgotPassSuccessFeedbackPage from "../pages/Auth/ForgotPassSuccessFeedbackPage";
import { PublicAuth, Private } from './Route';
import { ROUTES } from "../constants/routes";
import MyClinicsPage from "../pages/MyClinics/MyClinicsPage";
import ClinicDetailPage from "../pages/MyClinics/ClinicDetailPage";
import MyProfilePage from "../pages/MyProfile/MyProfilePage";



const Routes: React.FC = () => {    
    return (
      <ReactDomRoutes>
            <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.MY_PATIENTS} />} />
            <Route path={ROUTES.LOGIN} element={<PublicAuth component={LoginPage} />} />
            <Route path={ROUTES.REGISTER} element={<PublicAuth component={RegisterPage} />} />
            <Route path={ROUTES.FORGOT_PASS} element={<PublicAuth component={ForgotPassPage} />} />
            <Route path={ROUTES.RESET_PASS} element={<PublicAuth component={ResetPassPage} />} />
            <Route path={ROUTES.REGISTER_SUCCESS} element={<PublicAuth component={RegisterSuccessFeedbackPage} />} />
            <Route path={ROUTES.FORGOT_PASS_SUCESS} element={<PublicAuth component={ForgotPassSuccessFeedbackPage} />} />
            <Route path={ROUTES.MY_CLINICS} element={<Private component={MyClinicsPage} />} />
            <Route path={ROUTES.CLINIC_DETAIL} element={<Private component={ClinicDetailPage} />} />
            <Route path={ROUTES.MY_PROFILE} element={<Private component={MyProfilePage} />} />
        </ReactDomRoutes>
    );
};

export default Routes;