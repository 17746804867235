import { Gender } from "../domain/interfaces/Patient";
import { AnthropometricAgeChart } from "../pages/MyPatients/Interations/Anthropometric/types/AnthropometricAgeChart";
import { AnthropometricChildCaculateType } from "../pages/MyPatients/Interations/Anthropometric/services/types";
import { Anthropometric } from "../pages/MyPatients/Interations/Anthropometric/types/Anthropometric";
import api from "./api";

export default class AnthropometricService {
  
  public static getAgeWeightChartAnthropometrics(ageInMonths: number, gender: Gender): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometric-charts/age-weight?ageInMonths=${ageInMonths}&gender=${gender}`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeWeightChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeWeightChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getAgeHeightChartAnthropometrics(ageInMonths: number, gender: Gender): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometric-charts/age-height?ageInMonths=${ageInMonths}&gender=${gender}`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeHeightChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeHeightChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getAgeBMIChartAnthropometrics(ageInMonths: number, gender: Gender): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometric-charts/age-bmi?ageInMonths=${ageInMonths}&gender=${gender}`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeBMIChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeBMIChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static calculateChildAnthropometricResult(getChildAnthropometricResult: AnthropometricChildCaculateType): Promise<Anthropometric> {
    return new Promise((resolve, reject) => {
      const { patientId, height, weight, birthDate, gender } = getChildAnthropometricResult; 
      const url = `/${patientId}/anthropometrics/calculate-child-result?height=${height}&weight=${weight}&birthDate=${birthDate}&gender=${gender}`;
      api.get(url)
        .then((response) => {
          if(response.status === 204) return null;    
          resolve(response.data as Anthropometric);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}



