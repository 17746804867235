import * as React from 'react';
import Paper from '@mui/material/Paper';

import TablePagination from '@mui/material/TablePagination';
import { Box, SxProps } from '@mui/material';
import AppTableCell from './components/AppTableCell';

import { useSelector } from 'react-redux';
import { LayoutState } from '../../../state/reducers/layout.reducer';


export interface AppTableColumn {
  prop: string;
  label: string;
  maxWidth?: string;
  align?: "right" | "left" | "center" | undefined;
}

export interface AppTableRowData {
  [key: string]: {
    value: string | number | JSX.Element;
    actions?: React.ReactNode;
    onClick?: () => void;
    render?: (value: string | number | JSX.Element) => React.ReactNode;
  };
}

export interface AppTablePagination {
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  page: number;
  count: number;
}

export interface AppTableProps {
  columns: AppTableColumn[];
  rows: AppTableRowData[];
  pagination?: AppTablePagination;
  setPagination?: React.Dispatch<React.SetStateAction<AppTablePagination>>;
}

const AppTable: React.FC<AppTableProps> = ({ columns, rows, pagination, setPagination }) =>{

  const [columnsState, setColumnsState] = React.useState<AppTableColumn[]>([]);
  const [columnsSliced, setColumnsSliced] = React.useState<boolean>(false);

  const layout = useSelector((state: any) => state.layout as LayoutState);

  React.useEffect(()=> {
    if (layout.windowSize.width < 960) 
    {
      setColumnsState(()=> columns.slice(0, 1));
      setColumnsSliced(true);
    }else 
    {
      setColumnsState(()=> columns);
      setColumnsSliced(false);
    }
  }, [layout.windowSize.width, columns])


  const handleChangePage = (event: unknown, newPage: number) => {

    if(!setPagination) return;
   
    setPagination((p)=>({
      ...p,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!setPagination) return;
  
    setPagination((p) => ({
      ...p,
      rowsPerPage: parseInt(event.target.value)
    }));
  };


  const AppTableRow = ({ id, children }: { id: string, children: React.ReactNode }) => {

    const normalStyle = {
      display: "flex"
    }

    return (
      <Box className={"nutrifit-app-table-row"} key={`tr-${id}`} sx={normalStyle}>
        {children}
      </Box>
    );
  }

  const AppTableHeader = ({ children }: {  children: React.ReactNode }) => {

    const normalStyle = {
      backgroundColor: "#e6e6e6",
      display: "flex",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      height: "42px"
    } as SxProps;

    return (
      <Box sx={normalStyle}>
        {children}
      </Box>
    );
  }

  const AppTableBody = ({ children }: { children: React.ReactNode }) => {

    const normalStyle = {
      display: "flex",
      flexDirection: "column",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px"
    } as SxProps;

    return (
      <Box sx={normalStyle}>
        {children}
      </Box>
    );
  }

  const AppClicableValue = ({ children, onClick }: { children: React.ReactNode, onClick: () => void }) => {
    return (
      <Box onClick={onClick} sx={{
        cursor: "pointer", 
        "&:hover": {
          textDecoration: "underline"
        }
      }}>{children}</Box>
    );
  }

  return (
    <Paper elevation={0} sx={{ width: '100%'}}>

        <Box sx={{
          border: "1px solid #e6e6e6", 
          borderRadius: "8px",
          ".nutrifit-app-table-row .nutrifit-app-table-cell": {
            borderLeft: "1px solid #efefef"
          },
        ".nutrifit-app-table-row": {
          borderBottom: "1px solid #efefef",
          minHeight: "36px"
        },
        ".nutrifit-app-table-row:last-child": {
          borderBottom: "none"
        },
        ".nutrifit-app-table-row .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAccordion-root": {
          boxShadow: "none",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px"
        },
        ".nutrifit-app-table-row .nutrifit-resume-table": {
          
          marginLeft: "8px",
          width: "calc(100% - 16px)"
        } ,
         ".nutrifit-app-table-row .nutrifit-resume-table td": {
          borderLeft: "1px solid #e6e6e6",
          borderBottom: "1px solid #e6e6e6",
          padding: "4px 8px",
             fontSize: "12px"
        } ,
        ".nutrifit-app-table-row .nutrifit-resume-table td:first-of-type": {
          borderLeft: "none",
          borderBottom: "1px solid #e6e6e6",
            padding: "4px 8px",
            fontSize: "12px"
        } ,

        ".nutrifit-app-table-row .nutrifit-resume-table tr:first-of-type td": {
          // borderTop: "1px solid #e6e6e6",
        } ,

         ".nutrifit-app-table-row .nutrifit-resume-table tr:last-child td": {
          borderBottom: "none"
        } ,
        ".nutrifit-app-table-row .nutrifit-resume-table td:last-child": {
          // borderRight: "1px solid #e6e6e6",
        } ,
           
          }} >
      
          <AppTableHeader>
          {columnsState.map((column) => (
              <AppTableCell
                key={column.prop}
                align={column.align}
                maxWidth={column.maxWidth}
              >
                {column.label}
              </AppTableCell>
            ))}
          </AppTableHeader>

          <AppTableBody>
            {rows
              .map((row: AppTableRowData, index: number) => {
                return (
                  <AppTableRow key={`tr-${index}`} id={`tr-${index}`}>
                    {columnsState.map((column: AppTableColumn, columnIndex:number) => {
                      const rowData = row[column.prop];
                      
                      return (
                        <AppTableCell
                          align={column.align}
                          key={column.prop}
                          maxWidth={column.maxWidth}
                          expansible={columnsSliced &&  columnIndex === 0}
                          expansibleTitle={rowData?.value?.toString()}
                        >
                          <>
                          {!columnsSliced && <>
                            {rowData?.render && rowData.render(rowData?.value)}
                            {rowData?.onClick && <AppClicableValue onClick={rowData.onClick}>{rowData?.value}</AppClicableValue>}
                            {!rowData?.render && !rowData?.onClick && rowData?.value}

                          </>}

                          {columnsSliced && <>
                            <table className={"nutrifit-resume-table"}>
                              <tbody>
                              {columns.map((column, index)=>{
                                const slicedRowData = row[column.prop];
                                return (
                                  
                                  <tr>
                                    <td><strong>{column.label}</strong></td><td align={"left"}>
                                      {slicedRowData?.render && slicedRowData.render(slicedRowData?.value)}
                                      {slicedRowData?.onClick && <AppClicableValue onClick={slicedRowData.onClick}>{slicedRowData?.value}</AppClicableValue>}
                                      {!slicedRowData?.render && !slicedRowData?.onClick && slicedRowData?.value}
                                    </td>
                                  </tr>
                                );
                              })}
                              </tbody>
                            </table>
                          </>}

                        </>
                        </AppTableCell>
                      );
                    })}
                  </AppTableRow>
                );
              })}
          </AppTableBody>

        </Box>
      
      
      {pagination && (

      <TablePagination
        rowsPerPageOptions={pagination.rowsPerPageOptions}
        component="div"
        count={pagination.count}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
      />
      )}
    </Paper>
  );

}

export default AppTable;