import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import AppFormContainer from '../../components/layout/AppFormContainer';
import registerSuccessPicture from '../../assets/cadastro-sucesso.svg';


const RegisterSuccessFeedbackPage: React.FC = () => {
    return (
        <>
            <AppFormContainer
                pagePicture={registerSuccessPicture}
                pageOrientation='Verifique seu e-mail para acessar o sistema.'
                subtitle='Cadastro realizado com sucesso!'
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mt: 3}}>

                    <CheckCircleIcon sx={{ color: "green", fontSize: "8rem" }} />
                </Box>

            </AppFormContainer>
        </>
    );
}

export default RegisterSuccessFeedbackPage;