import { FC, useCallback, useState } from "react";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import { SectionProps } from "./interfaces/SectionProps";
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import { Alert, AlertColor, Box, Grid, SvgIconTypeMap, Typography } from "@mui/material";
import AppRadioButton from "../../../../components/forms/AppRadioButton";
import { Face, Hearing, SentimentSatisfied } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useAnamnesisStore } from "../../Stores/AnamnesisStore/AnamnesisStore";
import { ApiMetabolicScreeningItem, MetabolicScreeningItem } from "../../Stores/AnamnesisStore/types.d";
import AppHeartIcon from "../../../../components/icons/AppHeartIcon";
import AppLungsIcon from "../../../../components/icons/AppLungsIcon";
import AppStomachIcon from "../../../../components/icons/AppStomachIcon";
import AppMuscleIcon from "../../../../components/icons/AppMuscleIcon";
import AppSmellingIcon from "../../../../components/icons/AppSmellingIcon";
import AppBrainsIcon from "../../../../components/icons/AppBrainsIcon";
import AppSmilingFaceWithHeartIcon from "../../../../components/icons/AppSmilingFaceWithHeartIcon";
import AppBodyIcon from "../../../../components/icons/AppBodyIcon";
import AppWalkingIcon from "../../../../components/icons/AppWalkingIcon";
import AppSkinIcon from "../../../../components/icons/AppSkinIcon";
import AppOphthalmologyIcon from "../../../../components/icons/AppOphthalmologyIcon";
import { useAnamnesisRefDataStore } from "../../Stores/AnamnesisStore/AnamnesisRefDataStore";


const options = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 }
];


const isAllItensFilled = (data: MetabolicScreeningItem[], sections: AppMetabolicScreeningSection[]) => {
  const totalItensInSections = sections.reduce((acc, section) => acc + section.items.length, 0);
  return data.length === totalItensInSections;
}

const calculateScore = (data: { id: number, value: number }[]) => {
  return data.reduce((acc, item) => acc + item.value, 0);
}


const calculateResult = (score: number): { message:string, severity: AlertColor} | null => {
  if(score < 30) return {message: "Saudável", severity: "success"};
  if(score >= 30 && score < 40) return {message: "Hipersensibilidade", severity: "warning"};
  if(score >= 40 && score < 100) return {message: "Hipersensibilidade Absoluta", severity: "error"};
  if(score > 100) return {message: "Saúde Muito Ruim", severity: "error"};
  return null;
}

interface AppMetabolicScreeningSection {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string} | null;
  items: { label: string, id: number }[];
}

const getIconByName = (
  iconName: string
): OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string } | null => {
  switch (iconName) {
    case "AppHeartIcon":
      return AppHeartIcon;
    case "AppLungsIcon":
      return AppLungsIcon;
    case "AppStomachIcon":
      return AppStomachIcon;
    case "AppMuscleIcon":
      return AppMuscleIcon;
    case "AppSmellingIcon":
      return AppSmellingIcon;
    case "AppBrainsIcon":
      return AppBrainsIcon;
    case "AppSmilingFaceWithHeartIcon":
      return AppSmilingFaceWithHeartIcon;
    case "AppBodyIcon":
      return AppBodyIcon;
    case "AppWalkingIcon":
      return AppWalkingIcon;
    case "AppSkinIcon":
      return AppSkinIcon;
    case "AppOphthalmologyIcon":
      return AppOphthalmologyIcon;
    case "FaceIcon":
      return Face;
    case "HearingIcon":
      return Hearing;
    case "SentimentSatisfiedIcon":
      return SentimentSatisfied;
    default:
      return null;
  }
};

const generateSections = (data: ApiMetabolicScreeningItem[]): AppMetabolicScreeningSection[] => {

  const grouped = data.reduce((acc, item) => {
    
    if (!acc[item.groupPtBr]) {
      acc[item.groupPtBr] = { 
        title: item.groupPtBr, 
        icon: getIconByName(item.icon), 
        items: [] 
      };
    }
    
    acc[item.groupPtBr].items.push({ label: item.descPtBr, id: item.id });
    
    return acc;

  }, {} as Record<string, AppMetabolicScreeningSection>);

  return Object.values(grouped);
}

const getData = (id: number, data: MetabolicScreeningItem[]) => {
  const index = data.findIndex(item => item.id === id);
  return index >= 0 ? data[index].value : "";
}


const MetabolicScreeningSection: FC<SectionProps> = ({ complete }) => {

  const {data: refData } =  useAnamnesisRefDataStore(state => state);
  const sections = generateSections(refData.metabolicScreeningItemsRef);

  const store = useAnamnesisStore(state => state.data.metabolicScreenings);
  const setStateData = useAnamnesisStore(state => state.setData);
  const data = store;

  

  const setData = useCallback((id: number, value: number, data: MetabolicScreeningItem[]) => {
    const index = data.findIndex(item => item.id === id);

    if(index >= 0) 
    {
      data[index].value = value;
    }
    else 
    {
      data.push({ id: id, value });
    }
    setStateData("metabolicScreenings", [ ...data ]);
    
  }, [data]);



  const renderSection = useCallback((title: string, icon: any, items: { label: string, id: number }[], key: string, dataLoaded: MetabolicScreeningItem[]) => (
    <Box key={key}>
      <AppFormTitleDivider text={title} icon={icon} iconColor={"#777"} />
      <Grid container spacing={4} sx={{ mb: 4, p: 1 }}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} key={`radio-${item.id}-${index}`}>
            <AppRadioButton 
              value={getData(item.id, dataLoaded)}
              onChange={(event) => setData(item.id, parseInt(event.target.value), dataLoaded)}
              orientation="row"
              compact={true} 
              boldLabel={true} 
              label={item.label} 
              name={item.id.toString()} 
              options={options} 
              tooltip={
                <table cellSpacing="0" style={{ borderCollapse: 'collapse', minWidth:"300px" }}>
                  <tr>
                    <th style={{ padding: '8px', borderBottom: '1px solid #e6e6e6', borderRight: '1px solid #e6e6e6' }}>Escala</th>
                    <th align="left" style={{ padding: '8px', borderBottom: '1px solid #e6e6e6' }}>Significado</th>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6', borderRight: '1px solid #e6e6e6' }}>0</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6' }}>Não tem</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6', borderRight: '1px solid #e6e6e6' }}>1</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6' }}>Ocorre raramente</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6', borderRight: '1px solid #e6e6e6' }}>2</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6' }}>Ocorre com frequência</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6', borderRight: '1px solid #e6e6e6' }}>3</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #e6e6e6' }}>Ocorre frequentemente</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px', borderRight: '1px solid #e6e6e6' }}>4</td>
                    <td style={{ padding: '8px' }}>Ocorre sempre</td>
                  </tr>
                </table>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ), []);

  const result = calculateResult(calculateScore(data));
  const isAllFilled = isAllItensFilled(data, sections);

  return (
    <AppCheckAccordion title="Rastreamento Metabólico" complete={complete}>
      <Typography variant="body2" sx={{ mb: 2 }}>
       O rastreamento metabólico é um método que ajuda a identificar problemas de saúde relacionados à nutrição, como deficiências nutricionais, intolerâncias e alergias. Avalie cada sintoma baseado no perfil de saúde típica nos últimos 30 dias de acordo com a escala de pontos.
      </Typography>


      { sections.map((section, index) => renderSection(section.title, section.icon, section.items, "section-"+index, data))}
      
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Resultado Rastreamento Metabólico
        </Typography>

        {!isAllFilled &&
          <Alert severity="info">
            Sem resultado ainda. Preencha todos os campos para obter o resultado.
          </Alert>
        }
        {
          (isAllFilled && result) &&

          <p>
            <Alert severity={result.severity} >
              {result.message}
            </Alert>
            
          </p>

        }
      </Box>
     
    </AppCheckAccordion>
  );
};

export default MetabolicScreeningSection;