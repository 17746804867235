import React from "react";

import { SvgIcon, SvgIconProps } from '@mui/material';


const AppOfficeIcon: React.FC<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} >
            <svg width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.90625 3.375C5.68248 3.37502 5.46788 3.46392 5.30965 3.62215C5.15142 3.78038 5.06252 3.99498 5.0625 4.21875V10.125C5.06252 10.3488 5.15142 10.5634 5.30965 10.7216C5.46788 10.8798 5.68248 10.9687 5.90625 10.9688H9.28125V11.8125H11.8125V10.9688H15.1875C15.4113 10.9687 15.6259 10.8798 15.7841 10.7216C15.9423 10.5634 16.0312 10.3488 16.0312 10.125V4.21875C16.0312 3.99498 15.9423 3.78038 15.7841 3.62215C15.6259 3.46392 15.4113 3.37502 15.1875 3.375H5.90625ZM6.75 5.0625H14.3438V9.28125H6.75V5.0625ZM21.9375 6.75C21.4718 6.75 21.0938 7.128 21.0938 7.59375V11.8125H22.7812V7.59375C22.7812 7.128 22.4032 6.75 21.9375 6.75ZM19.4062 8.4375C18.9405 8.4375 18.5625 8.8155 18.5625 9.28125V11.8125H20.25V9.28125C20.25 8.8155 19.872 8.4375 19.4062 8.4375ZM4.21875 12.6562C3.753 12.6562 3.375 13.0343 3.375 13.5C3.375 13.9657 3.753 14.3438 4.21875 14.3438V22.7812C4.21717 22.8931 4.23782 23.0041 4.27952 23.1078C4.32121 23.2116 4.38311 23.306 4.46161 23.3856C4.54012 23.4653 4.63367 23.5285 4.73682 23.5716C4.83998 23.6148 4.95068 23.637 5.0625 23.637C5.17432 23.637 5.28502 23.6148 5.38818 23.5716C5.49133 23.5285 5.58488 23.4653 5.66339 23.3856C5.74189 23.306 5.80379 23.2116 5.84548 23.1078C5.88718 23.0041 5.90783 22.8931 5.90625 22.7812V16.875H15.1875V22.7812C15.1875 23.2453 15.5672 23.625 16.0312 23.625H21.9375C22.4016 23.625 22.7812 23.2453 22.7812 22.7812V14.3438C23.247 14.3438 23.625 13.9657 23.625 13.5C23.625 13.0343 23.247 12.6562 22.7812 12.6562H4.21875ZM18.1406 16.0312H19.8281C20.061 16.0312 20.25 16.2203 20.25 16.4531C20.25 16.686 20.061 16.875 19.8281 16.875H18.1406C17.9078 16.875 17.7188 16.686 17.7188 16.4531C17.7188 16.2203 17.9078 16.0312 18.1406 16.0312ZM18.1406 20.25H19.8281C20.061 20.25 20.25 20.439 20.25 20.6719C20.25 20.9048 20.061 21.0938 19.8281 21.0938H18.1406C17.9078 21.0938 17.7188 20.9048 17.7188 20.6719C17.7188 20.439 17.9078 20.25 18.1406 20.25Z" />
            </svg>
        </SvgIcon>
    );

}

export default AppOfficeIcon;