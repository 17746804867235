import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import AppFormContainer from '../../components/layout/AppFormContainer';
import registerSuccessPicture from '../../assets/cadastro-sucesso.svg';


const ForgotPassSuccessFeedbackPage: React.FC = () => {

    return (
        <>
            <AppFormContainer
                pagePicture={registerSuccessPicture}
                pageOrientation='Verifique seu e-mail para redefinir sua senha.'
                subtitle='Redefinição de senha solicitada com sucesso!'
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mt: 3}}>

                    <CheckCircleIcon sx={{ color: "green", fontSize: "8rem" }} />
                </Box>

            </AppFormContainer>
        </>
    );
}

export default ForgotPassSuccessFeedbackPage;