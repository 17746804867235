import { Box, IconButton, Menu, MenuItem, SxProps, Theme, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom";
import AppHamburgerIcon from "../../../components/icons/AppHamburgerIcon";

export interface CardInfoProps {
    title: string,
    sx?: SxProps<Theme>;
    icon?: React.ReactNode;
    link?: string
    data?: any,
    menus?: CardInfoMenu[]
}

export interface CardInfoMenu {
    label: string,
    icon: React.ReactNode,
    onClick: () => void
}

const CardInfo: FC<CardInfoProps> = (props) => {
    const navigate = useNavigate();
    const { title, icon, sx, link, data, menus } = props;

    const handleNavigate = useCallback(() => {
        navigate(link || "", {
            state: data
        })
    }, [data, link, navigate]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <Box
            sx={{
                p: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                width: "100%",
                backgroundColor: "#FFF",
                borderRadius: "12px",
                border: "1px solid #E0E0E0",
                cursor: "pointer",
                ...sx
            }}>
            <Box
                onClick={handleNavigate}
                sx={{ display: "flex", alignItems: "center" }}
            >
                {icon && icon}
                <Typography variant="body1" component="p" sx={{ fontWeight: "bold", textAlign: "left" }} ml={1}>
                    {title}
                </Typography>
            </Box>

            {menus &&
                <>
                    <IconButton
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <AppHamburgerIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {menus.map((menu, index) => (
                            <MenuItem key={index} onClick={menu.onClick}>
                                <Box sx={{ mr: 1, color: "rgba(0, 0, 0, 0.54)" }}>{menu.icon}</Box>
                                {menu.label}
                            </MenuItem>
                        ))}
                       
                    </Menu>
                </>
            }
        </Box>
    );
}

export default CardInfo;