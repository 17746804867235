import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../../services/api';
import { updateUserAndToken, clearUserAndToken as cleanUserAndToken, getAuthData as getAuthData, updateUserStorage } from '../../storage/storageAuthToken';

interface Props {
    children: React.ReactElement
}

export interface User {
    email: string;
    name: string;
    id: string;
    phone: string;
    document: Document;
    profileImage?: string;
}

export interface Document {
    number: string;
    type: string;
}

interface AuthContextData {
    user: User;
    authData: AuthData,
    login(credetials: LoginCredentials): Promise<void>;
    updateToken(refreshToken: RefreshToken): Promise<void>;
    confirmEmail(confirmarEmail: ConfirmEmail): Promise<void>;
    loginGoogle(token: string): Promise<boolean>;
    logout(): void;
    updateUser(user: User): void;
}

export interface LoginCredentials {
    email: string;
    password: string;
}

export interface RefreshToken {
    refreshToken: string;
}

export interface ConfirmEmail {
    email: string;
    token: string;
}

export interface AuthData {
    token: string;
    user: User;
    refreshToken?: string;
}

export interface LoginResponseData {
    accessToken: string;
    expiresIn: Date;
    refreshToken: string;
    userToken: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<Props> = ({ children }) => {

    const persistUserAndToken = (data: LoginResponseData) => {
        cleanUserAndToken();
        updateUserAndToken(data);
        const { accessToken: token, refreshToken, userToken } = data;
        
        setData({
            token,
            user: userToken,
            refreshToken
        });
    }

    const [data, setData] = useState<AuthData>(() => {
        return getAuthData();
    });

    const login = useCallback(async ({ email, password }: LoginCredentials) => {
        
        const response = await api.post('/users/login', {
            email,
            password,
        }); 

        if(response.status === 200)
        {
            persistUserAndToken(response.data as LoginResponseData);
            return;
        }

        throw response.data;      
                    
    }, []);

    const confirmEmail = useCallback(async ({ email, token }: ConfirmEmail) => {

        const response = await api.post('/users/confirm-email', {email,token});

        if(response.status === 200)
            return;

        throw response.data;

    }, []);

    const loginGoogle = useCallback(async (token: string) : Promise<boolean> => {

        const response = await api.post(`/users/login-google?tokenId=${token}`);

        if(response.status === 200)
        {
            persistUserAndToken(response.data as LoginResponseData);
            return true;
        }

        if(response.status === 204)
        {
            return false;
        }

        throw response.data;

    }, []);

    const logout = useCallback(() => {
        cleanUserAndToken();
        setData({} as AuthData);
        api.defaults.headers.common['Authorization'] = '';
    }, []);

    const updateUser = useCallback(
        (user: User) => {
            setData(data=>({...data, user }));

            updateUserStorage(user);
        },
        [setData],
    );

    const updateToken = useCallback(async ({ refreshToken }: RefreshToken) => {

        const response = await api.post('/users/refresh-token', {
            refreshToken
        });

        if(response.status === 200)
        {
            persistUserAndToken(response.data as LoginResponseData);
            return;
        }

        throw response.data;
    }, []);

    return (
        <AuthContext.Provider
            value={{ user: data.user, login, logout, updateUser, loginGoogle, confirmEmail, authData: data, updateToken }}>
            {children}
        </AuthContext.Provider>
    );
}


export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);
    return context;
}