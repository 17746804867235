import { createSvgIcon } from '@mui/material';


const AppLungsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M15 5L15 15.46875L11.625 17.875L9.34375 17.0625L8.65625 18.9375L10.03125 19.40625L8.28125 21.3125L9.71875 22.6875L12.46875 19.71875L12.5 19.6875L12.96875 19.375C12.984375 19.90625 13 20.382813 13 21C13 23.761719 10.992188 25 9 25L6.90625 25C6.402344 25 6 24.457031 6 24.09375C6 17.023438 9.863281 12 11.09375 12C11.417969 12 12.273438 12.355469 12.71875 15.75L14 14.84375L14 12.4375C13.414063 10.945313 12.515625 10 11.09375 10C8 10 4 16.839844 4 24.09375C4 25.648438 5.351563 27 6.90625 27L9 27C12.476563 27 15 24.476563 15 21C15 19.988281 14.957031 18.984375 14.90625 18L16 17.21875L17.09375 18C17.042969 18.984375 17 19.988281 17 21C17 24.476563 19.523438 27 23 27L25.09375 27C26.648438 27 28 25.648438 28 24.09375C28 16.839844 24 10 20.90625 10C19.484375 10 18.585938 10.945313 18 12.4375L18 14.84375L19.28125 15.75C19.726563 12.355469 20.582031 12 20.90625 12C22.136719 12 26 17.023438 26 24.09375C26 24.457031 25.597656 25 25.09375 25L23 25C21.007813 25 19 23.761719 19 21C19 20.382813 19.015625 19.90625 19.03125 19.375L19.5 19.6875L19.53125 19.71875L22.28125 22.6875L23.71875 21.3125L21.96875 19.40625L23.34375 18.9375L22.65625 17.0625L20.375 17.875L17 15.46875L17 5Z" />
  </svg>,
  'AppLungs'
);

export default AppLungsIcon;