import { ArrowBack, Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import AppButton from '../forms/AppButton';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ACTIONS } from '../../constants/routes';

export interface AppDetailPageHeaderProps {
  title: string;
  action?: string;
  editRoute?: string;
}


const AppDetailPageHeader: React.FC<AppDetailPageHeaderProps> = ({ action, editRoute, title }) => {

  const navigate = useNavigate();
  const [mode, setMode] = React.useState(ROUTE_ACTIONS.VIEW);

  React.useEffect(() => {
    if (action) {
      switch (action) {
        case ROUTE_ACTIONS.EDIT:
          setMode("Edição");
          break;
        case ROUTE_ACTIONS.VIEW:
          setMode("Visualização");
          break;
        default:
          setMode("Visualização");
      }
    }
  }, [action]);


  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1 }}>
        <IconButton onClick={() => { navigate(-1) }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          {title}  <Typography component={"span"} sx={{ color: "#ccc" }}>Modo {mode}</Typography>
        </Typography>
      </Box>

      {(action === ROUTE_ACTIONS.VIEW && editRoute) &&
        <Box>
          <AppButton startIcon={<Edit />} variant="contained" color="primary" onClick={() => { navigate(editRoute) }} label='Editar'></AppButton>
        </Box>
      }


    </Box>
  );
}

export default AppDetailPageHeader;