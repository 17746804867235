import {Box, InputLabel, MenuItem, Select, SelectProps, SxProps, Theme} from '@mui/material';
import React  from 'react'
import AppInputError from './components/InputErro';
import {useTheme} from "@mui/material/styles";

export type AppSelectProps = SelectProps<any> & {
  options: AppSelectOption[];
  errorMessage?: string;
  boxSx?: SxProps<Theme>;
  optional?: boolean,
  disabled?: boolean,
}

export interface AppSelectOption {
  value: any;
  label: string;
}

const AppSelect: React.FC<AppSelectProps> = (props) => {
    const theme = useTheme();
  const { errorMessage, boxSx } = props;

  const hasError = errorMessage !== undefined && errorMessage !== null && errorMessage !== "";
  const labelColor = props.optional || props.disabled ? theme.palette.text.disabled  : theme.palette.text.primary

  let otherProps = { ...props };
  delete otherProps.boxSx;
  delete otherProps.errorMessage;

  return (
    <Box sx={{ position:"relative", ...boxSx}}>
      <Select
        {...otherProps}
          label={ <InputLabel sx={{ position:"initial", color: labelColor, fontSize: "10px", fontWeight:"bold" }}>
              {props.label}
          </InputLabel>}
        labelId={`row-select-${props.name}`}
        id={`row-select-${props.name}`}
        error={hasError}
        value={props.value||""}
      >
        {props.options.map((option, index) => {
          return (
            <MenuItem selected={props.value === option.value} key={index} value={option.value}>{option.label}</MenuItem>
          );
        })}
      </Select>
      <AppInputError error={errorMessage} />
    </Box>
  )
}

export default AppSelect;