import { BodyCompositionType } from "../enums/BodyCompositionType";
import { PatientType } from "../enums/PatientType";
import { SkinfoldProtocolType } from "../enums/SkinfoldProtocolType";
import { SkinfoldFields } from "../../../Stores/Anthropometric/types";
import { Anthropometric } from "../types/Anthropometric";

function isUsed(skinfoldProtocolType: SkinfoldProtocolType, protocols: SkinfoldProtocolType[]): boolean {
    return protocols.includes(skinfoldProtocolType);
}

export function getDefaultAnthropometric(): Anthropometric {
    return {
        id: crypto.randomUUID(),
        createdDate: new Date(),
        updatedDate: new Date(),
        height: 0,
        idealWeight: 0,
        patientType: PatientType.Adult,
        weight: 0,
        description: "",
        bodyComposition: {
            id: crypto.randomUUID(),
            bmi: 0,
            bodyCompositionType: BodyCompositionType.SkinfoldProtocol,
            createdDate: new Date(),
            updatedDate: new Date(),
            bodyCompositionResult: {
                id: crypto.randomUUID(),
                results: [],
                bodyDensity: 0,
                fatMass: 0,
                fatMassPercentage: 0,
                leanMass: 0,
                leanMassPercentage: 0,
                sumOfSkinFolds: 0,
                createdDate: new Date(),
                updatedDate: new Date()
            },
            skinfoldProtocol: {
                id: crypto.randomUUID(),
                abdominal: 0,
                biceps: 0,
                middleAxillary: 0,
                medialCalf: 0,
                subscapularis: 0,
                suprailiaca: 0,
                thigh: 0,
                thorax: 0,
                triceps: 0,
                protocol: 3,
                createdDate: new Date(),
                updatedDate: new Date()
            },
            bioimpedance: {
                id: crypto.randomUUID(),
                bmi: 0,
                fatMassPercent: 0,
                fatMass: 0,
                leanMassPercent: 0,
                leanMass: 0,
                bodyWaterPercent: 0,
                bodyWater: 0,
                boneWeight: 0,
                residualWeight: 0,
                muscleWeight: 0,
                visceralFatPercent: 0,
                metabolicAge: 0,
                skeletalMusclesPercent: 0,
                createdDate: new Date(),
                updatedDate: new Date()
            },
            weltmanProtocol: {
                id: crypto.randomUUID(),
                abdomen1: 0,
                abdomen2: 0,
                createdDate: new Date(),
                updatedDate: new Date()
            }
        },
        circuferences: {
            id: crypto.randomUUID(),
            createdDate: new Date(),
            updatedDate: new Date(),
            boneDiameters: {
                femur: 0,
                fistArm: 0
            },
            lowerLimbesMeasurements: {
                calfRight: 0,
                calfLeft: 0,
                thighRight: 0,
                thighLeft: 0,
                leftProximalThigh: 0,
                rightProximalThigh: 0
            },
            steamLimbesMeasurements: {
                neck: 0,
                shoulder: 0,
                breastplate: 0,
                waist: 0,
                abdomen: 0,
                hip: 0
            },
            upperLimbesMeasurements: {
                relaxedArmRight: 0,
                relaxedArmLeft: 0,
                contractedArmRight: 0,
                contractedArmLeft: 0,
                forearmRight: 0,
                forearmLeft: 0,
                fistRight: 0,
                fistLeft: 0
            }
        }
    };
}

export const skinfoldProtocolFields: SkinfoldFields = {
    biceps: {
        name: "biceps",
        label: "Bíceps",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley,
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    triceps: {
        name: "triceps",
        label: "Tríceps",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley,
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    abdominal: {
        name: "abdominal",
        label: "Abdominal",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    suprailiaca: {
        name: "suprailiaca",
        label: "Suprailíaca",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    middleAxillary: {
        name: "middleAxillary",
        label: "Axilar Média",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    subscapularis: {
        name: "subscapularis",
        label: "Subescapular",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    thorax: {
        name: "thorax",
        label: "Tórax",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    thigh: {
        name: "thigh",
        label: "Coxa",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    medialCalf: {
        name: "medialCalf",
        label: "Panturrilha Medial",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [] as SkinfoldProtocolType[];
            return isUsed(skinfoldProtocolType, protocols);
        }
    }
};


