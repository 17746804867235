import { Button, SxProps, Theme } from "@mui/material";
import { FC } from "react"

export interface ButtonAddAvaliationProps {
    label: string,
    icon:  React.ReactNode;
    sx?: SxProps<Theme>;
    [x: string]: any;
}

const ButtonAvaliationAdd: FC<ButtonAddAvaliationProps> = (props) => {

    const { label, icon, sx } = props;
    return (
        <Button  sx={{ 
            display: "flex", 
            flexDirection:"column", 
            borderRadius: "12px", 
            width: "120px",
            gap: 1,  
            backgroundColor: "#FAFBFD", 
            border:"1px solid #E0E7F1" , 
            p: "15px", 
            color: "#746F6F",
            ":hover": {
                backgroundColor: "#E1E2E4",
            },
            textTransform:"none !important",
            ...sx}}
            {...props}
            >
            {icon && icon}
            {label}

       </Button>
    );
}

export default ButtonAvaliationAdd;