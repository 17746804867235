import { FC } from "react";
import AppMainHeader from "../../../../components/layout/AppMainHeader";
import AppCard from "../../../../components/layout/AppCard";
import AppMainLayout from "../../../../components/layout/AppMainLayout";
import { Grid } from "@mui/material";
import AppTextField from "../../../../components/forms/AppTextField";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import AppSelectable from "../../../../components/forms/AppSelectable";
import { Bookmark, BookmarkAdd, ReportOff, Save, Share, Visibility } from "@mui/icons-material";
import AppInterationsPageHeader, { AppInterationHeaderAction } from "../components/AppInterationsPageHeader";

const daysInWeek = [
  { value: "01", label: "Domingo" },
  { value: "02", label: "Segunda" },
  { value: "03", label: "Terça", disabled: true },
  { value: "04", label: "Quarta" },
  { value: "05", label: "Quinta" },
  { value: "06", label: "Sexta" },
  { value: "07", label: "Sábado" }
];

const headerActions: AppInterationHeaderAction[] = [
  {
    label: "Salvar",
    featured: true,
    icon: <Save />,
    onClick: () => { }
  },
  {
    label: "Salvar como modelo",
    showAsIconButton: true,
    featured: true,
    icon: <BookmarkAdd />,
    onClick: () => { }
  },
  {
    label: "Compartilhar",
    featured: true,
    showAsIconButton: true,
    separated: true,
    icon: <Share />,
    onClick: () => { }
  },
  {
    label: "Visualizar",
    icon: <Visibility />,
    onClick: () => { }
  },
  {
    label: "Carregar Modelo",
    icon: <Bookmark />,
    onClick: () => { }
  },
  {
    label: "Inativar Plano",
    separated: true,
    icon: <ReportOff />,
    onClick: () => { }
  },
]

const DietPlanPage: FC = () => {
  return (

    <AppMainLayout>
      <AppMainHeader hasBreadCrumbs={true} />
      <AppCard>
        <AppInterationsPageHeader title={"Novo plano alimentar"} actions={headerActions} />
        <Grid container spacing={2} sx={{ mt: 0, mb: 4, p: 1 }}>
          <Grid item xs={12} sm={12} md={5}>
            <AppTextField
              label="Descrição do plano"
              name="descricaoPlano"
              labelType={LabelType.INLINE}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={7} flex={1} alignItems={"flex-end"}>
            <Grid container justifyContent={{ md: "flex-end" }}>
              <AppSelectable
                selectableValues={daysInWeek}
                label="Dias da semana"
                onChanged={(selectedValues) => console.log(selectedValues)}
                value={["01", "02", "03", "05", "06"]}
              />
            </Grid>

          </Grid>
        </Grid>
      </AppCard>
    </AppMainLayout>

  );
}

export default DietPlanPage;