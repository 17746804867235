import * as Yup from 'yup';
import { AxiosError } from "axios";


interface ErrorMessages {
    messages: string[];
}

interface ErrorModel {
    errors: ErrorMessages;
}

export interface FormErrors {
    [Key: string]: string;
}

interface ErrorMessages {
    messages: string[];
}

interface ErrorModel {
    errors: ErrorMessages;
}

export default class FormService {
    private setErrors: any;
    private setFormErrors: any;
    private data: any = {};
    private setData: any = {};
  
    constructor(data:any, setData: any, setErrors:any, setFormErrors?:any) {
      this.data = data;
      this.setData = setData;
      this.setErrors = setErrors;
      this.setFormErrors = setFormErrors;
    }

    public setInputValue(inputName:string, value: any) {
      this.setData({...this.data, [inputName]: value});
    }
    
    public handleErros(err: any) {  
        if (err instanceof Yup.ValidationError) {
            var erros = this.getValidationErrors(err);  
            this.setFormErrors(erros);
            return;
        }        
  
        this.setErrors(this.getErrors(err));
    }

    public getErrors(err: any): string[] {
        let responseError: ErrorModel      
        if (err instanceof AxiosError && err.code !== "ERR_NETWORK") {
            responseError = err.response?.data as ErrorModel;
            return responseError.errors.messages;
        }
    
        if (err.status >= 400 && err.status < 500) {
            return err.errors.mensagens;
        } 
    
        return ["Houve uma falha inesperada. Contate o suporte técnico"];
    }
    
    public getValidationErrors(err: Yup.ValidationError): FormErrors {
    
        const { inner } = err;
        let errors: FormErrors = {};
        inner.forEach(error => {
            if(error.path !== null && error.path !== undefined)
                errors[error.path!] = error.message;
        });
    
        return errors;
    }

    public cleanErrors() {
        this.setErrors([]);
        this.setFormErrors({} as FormErrors);
    }    
}
