import React, { ChangeEvent, FormEvent, useState } from 'react';
import * as Yup from 'yup';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import AppLoading from '../../components/utils/AppLoading';
import AppErrorList from '../../components/forms/AppErrorList';
import AppButton from '../../components/forms/AppButton';
import AppFormContainer from '../../components/layout/AppFormContainer';
import { ROUTES } from '../../constants/routes';
import esqueciSenha from '../../assets/esqueci-senha.svg';
import FormService, { FormErrors } from '../../services/form.service';
import AppTextField from '../../components/forms/AppTextField';
import { Box, Divider, Grid } from '@mui/material';

const formSchema = Yup.object({
  email: Yup
    .string()
    .email("O e-mail deve ser válido.")
    .required('Obrigatório informar o email.')
});

const ForgotPassPage: React.FC = () => {

  const navigate = useNavigate();

  const [errors, setErrors] = useState([] as string[]);
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formData, setFormData] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);

  const formErrosService = new FormService(formData, setFormData, setErrors, setFormErrors);


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id: name, value } = event.target;
    formErrosService.setInputValue(name, value);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    setIsLoading(true);
    formErrosService.cleanErrors();

    try {

      await formSchema.validate(formData, { abortEarly: false });

      const response = await api.post(`/users/forgot-password?email=${formData.email}`);

      if (response.status !== 200)
        throw response.data;

      navigate(ROUTES.FORGOT_PASS_SUCESS);

    } catch (err) {
      formErrosService.handleErros(err);
    }
    setIsLoading(false);
  };

  return (

    <>
      <AppLoading isLoading={isLoading} />
      <AppFormContainer
        title='Recupere sua senha!'
        pagePicture={esqueciSenha}
        pageOrientation='Preencha seu e-mail para redefinir a sua senha.'
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{mt:1}}>
            <Grid item md={12} xs={12}>
              <AppTextField
                id="email"
                label="Seu E-mail:*"
                name="email"
                fullWidth
                autoFocus
                value={formData['email']}
                onChange={handleChange}
                errorMessage={formErrors['email']}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <AppButton
                endIcon={<ForwardToInboxIcon />}
                type="submit"
                fullWidth
                variant='contained'
                label='Enviar E-mail'
              />
            </Grid>
          </Grid>
        </form>
        <AppErrorList errors={errors} />
        <Divider sx={{ my: 2 }}>Ou</Divider>
        <Box sx={{display:"flex", justifyContent:"center"}}>
          <Link style={{color: "#00000099"}}  to="/login">Clique aqui para fazer login</Link>
        </Box>
      </AppFormContainer>
    </>
  );
}

export default ForgotPassPage;
