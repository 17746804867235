import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AppPeopleIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="17" viewBox="0 0 25 17" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.50001 0.333344C5.19917 0.333344 3.33334 2.19918 3.33334 4.50001V5.33334C3.33334 7.63418 5.19917 9.50001 7.50001 9.50001C9.80084 9.50001 11.6667 7.63418 11.6667 5.33334V4.50001C11.6667 2.19918 9.80084 0.333344 7.50001 0.333344ZM17.5 0.333344C15.1992 0.333344 13.3333 2.19918 13.3333 4.50001V5.33334C13.3333 7.63418 15.1992 9.50001 17.5 9.50001C19.8008 9.50001 21.6667 7.63418 21.6667 5.33334V4.50001C21.6667 2.19918 19.8008 0.333344 17.5 0.333344ZM17.5 2.00001C18.8783 2.00001 20 3.12168 20 4.50001V5.33334C20 6.71168 18.8783 7.83334 17.5 7.83334C16.1217 7.83334 15 6.71168 15 5.33334V4.50001C15 3.12168 16.1217 2.00001 17.5 2.00001ZM7.49838 11.1667C4.76671 11.1667 1.26225 12.7284 0.253912 14.4284C-0.368588 15.4784 0.224251 17 1.24675 17H11.2468H13.7533H23.7533C24.7758 17 25.3694 15.4784 24.7461 14.4284C23.7369 12.7284 20.23 11.1667 17.4984 11.1667C15.8731 11.1667 13.9841 11.7263 12.5 12.5322C11.0155 11.726 9.12416 11.1667 7.49838 11.1667ZM17.4984 12.8333C19.8067 12.8333 22.673 14.2005 23.3122 15.278C23.3213 15.293 23.3251 15.3125 23.3285 15.3333H14.9935C14.9858 15.0231 14.9112 14.7093 14.7445 14.4284C14.5804 14.1518 14.3317 13.8826 14.0495 13.6195C15.1174 13.1609 16.3725 12.8333 17.4984 12.8333Z"/>
            </svg>
        </SvgIcon>
    )
}

export default AppPeopleIcon;