import React, { useEffect } from 'react';
import { Close, Done, Info, Error } from '@mui/icons-material';
import { Box, Collapse, Typography } from '@mui/material';
import { AppToastMessage, useAppToast } from '../../../hooks/core/AppToastContextProvider';
import themes from '../../../styles/themes';

interface Props {
    message: AppToastMessage
}

const AppToastItem: React.FC<Props> = (props) => {

    const { message } = props;

    const colors = {
        "info": "#5bc0de",
        "success": "#5cb85c",
        "error": "#d9534f"
    }

    var backgroundColor = message.type === undefined ? colors["info"] : colors[message.type];

    const { removeToast } = useAppToast();

    useEffect(() => {
        const timer = setTimeout(() => {
            removeToast(message.id);
        }, 5000);
        return () => clearTimeout(timer);
    }, [removeToast, message.id]);


    return (
        <Collapse orientation="horizontal" in={true} >
            <Box sx={{ display: "flex", alignItems: "center", backgroundColor, mb: 1, maxWidth:"25rem", minWidth: "16rem", position: "relative", padding: "1rem", borderRadius: "12px", boxShadow: "0.2rem 0.2rem 0.8rem rgba(0, 0, 0, 0.2)" }} >

                {message.type === "info" && <Info sx={{ color: themes.palette.primary.contrastText, fontSize: "2rem", mr: 1.5 }} />}
                {message.type === "error" && <Error sx={{ color: themes.palette.primary.contrastText, fontSize: "2rem", mr: 1.5 }} />}
                {message.type === "success" && <Done sx={{ color: themes.palette.primary.contrastText, fontSize: "2rem", mr: 1.5 }} />}

                <Box>
                    <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", mb: "0.5rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: themes.palette.primary.contrastText, fontWeight: "bold" }}>{message.title}</Typography>
                        <Close
                            sx={{ cursor: "pointer", ml: 1, color: themes.palette.primary.contrastText, fontSize: "1rem" }}
                            onClick={() => { removeToast(message.id); }}
                        />
                    </Box>
                    <Typography sx={{ fontSize: "0.7rem", color: themes.palette.primary.contrastText }}>{message.description}</Typography>
                </Box>
            </Box>
        </Collapse>
    );
}

export default AppToastItem;