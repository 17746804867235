import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AnamnesisRefData, AnamnesisRefDataStore, ApiMetabolicScreeningItem } from './types.d';

const MINUTES_TO_CACHE_EXPIRES = 60;

export const useAnamnesisRefDataStore = create<AnamnesisRefDataStore>()(
    persist(
        (set) => ({
            data: {
                metabolicScreeningItemsRef: [] as ApiMetabolicScreeningItem[]
            },
            setData: (propName: keyof AnamnesisRefData, data: any) => {
                set((state) => {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            [propName]: data
                        }
                    }
                });
                localStorage.setItem(
                    'app-nutrifit-anamnesis-ref-data-store-timestamp',
                    Date.now().toString()
                );
            }
        }),
        {
            name: 'app-nutrifit-anamnesis-ref-data-store',
            getStorage: () => localStorage,
            onRehydrateStorage: () => {
                const storedTimestamp = localStorage.getItem('app-nutrifit-anamnesis-ref-data-store-timestamp');
                const currentTime = Date.now();

                if (storedTimestamp && currentTime - parseInt(storedTimestamp, 10) > MINUTES_TO_CACHE_EXPIRES * 60000) {
                    localStorage.removeItem('app-nutrifit-anamnesis-ref-data-store');
                    localStorage.removeItem('app-nutrifit-anamnesis-ref-data-store-timestamp');
                }
            },


        }
    )
);