export function getFormattedDate(date: string | undefined | Date) : string {
    if(date === undefined) return "";

    return new Date(date).toLocaleDateString();
    
}

export function getFormattedDateTime(date: string | undefined | Date) : string {
    if(date === undefined) return "";

    return new Date(date).toLocaleDateString('pt-BR', { hour: 'numeric', minute: 'numeric' }); 
}

export function getFormattedBackendDate(date: string | undefined | Date) : string {
    if(date === undefined) return "";

    if(!(date instanceof Date)) date = new Date(date);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}


