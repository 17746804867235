import { Gender, Patient } from "../../../domain/interfaces/Patient";
import { PatientType } from "../Interations/Anthropometric/enums/PatientType";

interface PatientTypes {
    [key: string]: string
}

export class PatientService {

    public static getPatientAge(patient: Patient): number {
        const { birthDate } = patient;
        
        if(birthDate === undefined) return 0;

        const today = new Date();
        let age = today.getFullYear() - Number(birthDate.substring(0, 4));
        const monthDifference = today.getMonth() - Number(birthDate.substring(5, 6));
        const dayDifference = today.getDate() - Number(birthDate.substring(8, 10));

        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }

        return age;
    }

    public static getAgeInMonths (patient: Patient): number {
        const { birthDate } = patient;
        const dateBirthDate: Date = birthDate ? new Date(birthDate) : new Date();
        const today = new Date();
        const yearsDifference = today.getFullYear() - dateBirthDate.getFullYear();
        const monthsDifference = today.getMonth() - dateBirthDate.getMonth();

        const ageInMonths = yearsDifference * 12 + monthsDifference;

        return ageInMonths >= 0 ? ageInMonths : 0;
    }

    public static getAgeInDays (patient: Patient): number {
        const { birthDate } = patient;
        const dateBirthDate: Date = birthDate ? new Date(birthDate) : new Date();
        const today = new Date();
        
        const ageInMilliseconds = today.getTime() - dateBirthDate.getTime();
        
        const ageInDays = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24));
        
        return ageInDays;
    }

    public static getPatientType (patient: Patient): PatientType {
        const patientAge = this.getPatientAge(patient);
        let patientType: PatientType = PatientType.Child;

        if(patientAge > 18 && patientAge < 60) 
            patientType = PatientType.Adult; 

        if(patientAge >= 60) 
            patientType = PatientType.SeniorCitizen; 

        return patientType;
    }

    public static getPatientTypeDescription (patientType: PatientType): string {
        const patientsTypes : PatientTypes = {
            Child: "Criança",
            SeniorCitizen: "Idoso",
            Adult: "Adulto"
        }

        return patientsTypes[PatientType[patientType]];
    }

    public static getPatientGenderDescription (patientGender: Gender | undefined): string {
        if(patientGender === undefined) return "--";

        const genderDescriptions = [
            "Não informado",
            "Masculino",
            "Feminino",
            "Outro"
        ];

        return genderDescriptions[patientGender];
    }
}