import { Gender } from "../../../../../domain/interfaces/Patient";
import { getFormattedNumber, round } from "../../../../../utils/numberUtils";
import { BodyCompositionItemResult, MassRecomendation } from "../types/BodyComposition/BodyCompositionItemResult";
import { BodyCompositionResults } from "../types/BodyComposition/BodyCompositionResults";
import BmiService from "./Bmi/BmiService";
import { AnthropometricCaculateType } from "./types";

type RecomendationsType = {
    minRecomendationPercentageFatMass: number,
    maxRecomendationPercentageFatMass: number,
    minRecomendationPercentageLeanMass: number,
    maxRecomendationPercentageLeanMass: number,
}

const femaleRecomendation: RecomendationsType = {
    minRecomendationPercentageFatMass: 14,
    maxRecomendationPercentageFatMass: 25,
    minRecomendationPercentageLeanMass: 75,
    maxRecomendationPercentageLeanMass: 86,
}

const maleRecomendation: RecomendationsType = {
    minRecomendationPercentageFatMass: 8,
    maxRecomendationPercentageFatMass: 20,
    minRecomendationPercentageLeanMass: 80,
    maxRecomendationPercentageLeanMass: 92,
}

export default abstract class AnthropometricProtocolService {

    protected anthropometricCalculate: AnthropometricCaculateType;
    protected bmiBodyResult: BodyCompositionItemResult;    
    protected weight: number;
    protected gender: number;

    protected recomendations: RecomendationsType;

    constructor(anthropometricCalculate: AnthropometricCaculateType) {
        this.anthropometricCalculate = anthropometricCalculate;
   
        const { weight, height, gender } = anthropometricCalculate;
        this.bmiBodyResult = new BmiService(weight, height).getBmiBodyCompositionResult();
        this.weight = weight;
        this.gender = gender;

        if(this.gender === Gender.Male) {
            this.recomendations = maleRecomendation;    
        } else {            
            this.recomendations = femaleRecomendation;
        }

    }

    public isWeigthAndHeight(): boolean {
        const { weight, height } = this.anthropometricCalculate;
        return weight > 0 && height > 0;
    }
    
    protected getBodyCompositionResults(fatMassPercentage: number) : BodyCompositionResults { 
        const fatMass = round(fatMassPercentage * this.weight / 100);
        const leanMassPercentage = round(100 - fatMassPercentage);
        const leanMass = round(leanMassPercentage * this.weight / 100);

        const { bodyCompositionResult } = this.anthropometricCalculate.data;

        return {
            id: bodyCompositionResult.id,
            fatMass,
            fatMassPercentage,
            leanMass,
            leanMassPercentage,
        } as BodyCompositionResults;
    }

    protected getLeanMassBodyCompositionResult(leanMassPercentage: number, leanMass: number) : BodyCompositionItemResult[] {
        return  [
            {
                id: crypto.randomUUID(),
                value: leanMassPercentage,
                valueFormatted: `${getFormattedNumber(leanMassPercentage)} %`,
                massRecomendation: this.getLeanMassRecomendation(leanMassPercentage),
                description: "% Massa Magra"            
            },
            {
                id: crypto.randomUUID(),
                value: leanMass,
                valueFormatted: `${getFormattedNumber(leanMass)} Kg`,
                massRecomendation: this.getLeanMassRecomendation(leanMassPercentage, false),
                description: "Massa Magra"            
            },
        ] as BodyCompositionItemResult[];
    }

    protected getFatMassBodyCompositionResult(fatMassPercentage: number, fatMass: number) : BodyCompositionItemResult[] {
        return  [
            {
                id: crypto.randomUUID(),
                value: fatMassPercentage,
                valueFormatted: `${getFormattedNumber(fatMassPercentage)} %`,
                massRecomendation: this.getFatMassRecomendation(fatMassPercentage),
                description: "% Massa Gorda"            
            },
            {
                id: crypto.randomUUID(),
                value: fatMass,
                valueFormatted: `${getFormattedNumber(fatMass)} Kg`,
                massRecomendation: this.getFatMassRecomendation(fatMassPercentage, false),
                description: "Massa Gorda"            
            },
        ] as BodyCompositionItemResult[];
    }

    protected getFatMassRecomendation(fatMassPercentage: number, isFatMassPercentageRecomendation: boolean = true ) : MassRecomendation {

        const { minRecomendationPercentageFatMass, maxRecomendationPercentageFatMass } = this.recomendations;

        let recomendation = `${getFormattedNumber(minRecomendationPercentageFatMass)} % - ${getFormattedNumber(maxRecomendationPercentageFatMass)} %`;

        if(!isFatMassPercentageRecomendation) recomendation = this.getFatMassDescriptionRecomendation();

        return this.getMassRecomendation(
            fatMassPercentage, 
            minRecomendationPercentageFatMass, 
            maxRecomendationPercentageFatMass, 
            recomendation);
    }

    private getLeanMassRecomendation(leanMassPercentage: number, isLeanMassPercentageRecomendation: boolean = true ) : MassRecomendation {

        const { minRecomendationPercentageLeanMass, maxRecomendationPercentageLeanMass } = this.recomendations;

        let recomendation = `${getFormattedNumber(minRecomendationPercentageLeanMass)} % - ${getFormattedNumber(maxRecomendationPercentageLeanMass)} %`;

        if(!isLeanMassPercentageRecomendation) recomendation = this.getLeanMassDescriptionRecomendation();

        return this.getMassRecomendation(
            leanMassPercentage, 
            minRecomendationPercentageLeanMass, 
            maxRecomendationPercentageLeanMass, 
            recomendation);
    }

    private getMassRecomendation(massPercentage: number, minRecomendation: number,  maxRecomendation: number,  recomendation: string) : MassRecomendation {

        if(massPercentage > minRecomendation && massPercentage < maxRecomendation) {
            return {
                description: "Muito bom",
                situationSeverity: "success",
                recomendation: recomendation
            };
        }

        let situationDescription = "Acima do Recomendado";
        if(massPercentage < minRecomendation) {
            situationDescription = "Abaixo do Recomendado";
        }

        return {
            description: situationDescription,
            situationSeverity: "error",
            recomendation: recomendation
        };
    }

    private getFatMassDescriptionRecomendation(): string {
        const { minRecomendationPercentageFatMass, maxRecomendationPercentageFatMass } = this.recomendations;
        const minFatMass = this.weight * minRecomendationPercentageFatMass / 100;
        const maxFatMass = this.weight * maxRecomendationPercentageFatMass / 100;
        return`${getFormattedNumber(minFatMass)} Kg - ${getFormattedNumber(maxFatMass)} Kg`;
    }

    private getLeanMassDescriptionRecomendation(): string {
        const { minRecomendationPercentageLeanMass, maxRecomendationPercentageLeanMass } = this.recomendations;
        const minLeanMass = this.weight * minRecomendationPercentageLeanMass / 100;
        const maxLeanMass = this.weight * maxRecomendationPercentageLeanMass / 100;
        return`${getFormattedNumber(minLeanMass)} Kg - ${getFormattedNumber(maxLeanMass)} Kg`;
    }
    
}