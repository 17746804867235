import { AuthData, LoginResponseData, User } from "../hooks/core/AuthProvider";
import { USUARIO_REFRESH_TOKEN_STORAGE, USUARIO_STORAGE, USUARIO_TOKEN_STORAGE } from "./storageConfig";

export function clearUserAndToken() {
    localStorage.removeItem(USUARIO_STORAGE);
    localStorage.removeItem(USUARIO_TOKEN_STORAGE);
    localStorage.removeItem(USUARIO_REFRESH_TOKEN_STORAGE);
}

export function updateUserAndToken(data: LoginResponseData) {
    clearUserAndToken();
    if(!data) return;
    const { accessToken: token, refreshToken, userToken } = data;
    localStorage.setItem(USUARIO_STORAGE, JSON.stringify(userToken));
    localStorage.setItem(USUARIO_TOKEN_STORAGE, token);
    localStorage.setItem(USUARIO_REFRESH_TOKEN_STORAGE, refreshToken);
}

export function updateUserStorage(usuario: User) {
    localStorage.setItem(USUARIO_STORAGE, JSON.stringify(usuario));
}

export function getAuthData(): AuthData {
    const user = localStorage.getItem(USUARIO_STORAGE);
    const token = localStorage.getItem(USUARIO_TOKEN_STORAGE);
    const refreshToken = localStorage.getItem(USUARIO_REFRESH_TOKEN_STORAGE);
    
    if (user && token && refreshToken) {
        return  { token, refreshToken, user: JSON.parse(user) };
    }

    return {} as AuthData;
}

