import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { LayoutState } from "../../../state/reducers/layout.reducer";
import AppTooltip from "../../../components/forms/AppTooltip";
import { FC } from "react";

type AppFormTitleDividerProps = {
    text: string,
    icon?: any,
    iconColor?: string
    tooltip?: string | JSX.Element;
    tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
};

const AppFormTitleDivider: FC<AppFormTitleDividerProps> = ({text, icon, iconColor, tooltip, tooltipPosition}) => {
  
  const layout = useSelector((state: any) => state.layout as LayoutState);

  if(!iconColor){
      iconColor = "#999";
  }

  const { isTablet } = layout.windowSize;

  return (
      <Box sx={{ 
        width: isTablet ? "100%": "50%",
        display: "flex", 
        justifyContent: isTablet ? "flex-start": "center", 
        alignItems: "center", 
        gap: 1, 
        margin: isTablet? "" : "0 auto", 
        mt:1, 
        mb:2
      }}>
          {!isTablet && <Box style={{ flexGrow: 1, borderBottom: "1px solid #ccc"  }}></Box>}
          <Box sx={{display:"flex", flexDirection:"row-reverse", alignItems:"center", gap:1}}>
             <Box sx={{display:"flex", alignItems:"center"}}>
              <Typography variant="h5" component="h2" sx={{ color: "#555", fontWeight:"bold" }}>{text}</Typography>
              <AppTooltip position={tooltipPosition}>
                {tooltip}
              </AppTooltip>
             </Box>
              {icon && <Box component={icon} sx={{ color: iconColor, fill:iconColor, fontSize: 24 }}></Box>}
          </Box>
          {!isTablet && <Box style={{ flexGrow: 1, borderBottom: "1px solid #ccc"  }}></Box>}
      </Box>
  );
}


export default AppFormTitleDivider;