import { ArrowBack, MoreVert } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import AppButton from "../../../../components/forms/AppButton";
import { useNavigate } from "react-router-dom";
import { LayoutState } from "../../../../state/reducers/layout.reducer";
import { useSelector } from "react-redux";
import { useState } from "react";


export interface AppInterationHeaderProps {
    title: string,
    subtitle?: string,
    actions: AppInterationHeaderAction[]
}

export interface AppInterationHeaderAction {
    label: string,
    showAsIconButton?: boolean,
    icon?: React.ReactNode,
    separated?: boolean,
    featured?: boolean,
    onClick: () => void | Promise<void>,
    disabled?: boolean,
    hide?: boolean
}

const AppInterationsPageHeader: React.FC<AppInterationHeaderProps> = ({title, subtitle, actions}) => {

    const navigate = useNavigate();
    const layout: LayoutState = useSelector((state: any) => state.layout);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const marginTop = layout.windowSize.isMobile ? "62px" : "0";

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: 1, position: "sticky", top: marginTop, zIndex: 1, backgroundColor: "#FFF", pb: 3, pt: 3, borderBottom: "2px solid #E0E0E0" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1 }}>
                <IconButton onClick={() => { navigate(-1) }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
                    {title}  <Typography component={"span"} sx={{ color: "#ccc" }}> {subtitle}</Typography>
                </Typography>
                
            </Box>

            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>

            {!layout.windowSize.isMobile &&
                    <>
                    {actions.filter(a => a.featured).map(action => {
                        if(action.hide) return null;
                        return(<>{
                            action.showAsIconButton ? 
                                <Tooltip title={action.label}>
                                    <IconButton onClick={action.onClick} sx={{ backgroundColor: "#F2F2F2" }}>
                                        {action.icon}
                                    </IconButton>
                                </Tooltip>
                            :
                                <AppButton
                                    startIcon={action.icon}
                                    variant="contained"
                                    color="primary"
                                    onClick={action.onClick}
                                    label={action.label}
                                    disabled={action.disabled}
                                ></AppButton>
                        }</>);
                    })}
                        
                        <Divider flexItem orientation="horizontal" sx={{ borderWidth: "1px!important" }} />
                    </>
                }
                <Tooltip title="Ações">
                    <IconButton
                        onClick={handleClick}
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVert />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                        paper: {
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {actions.map(action => {
                        if(action.hide) return null;
                        return(
                            <>
                               {action.separated && <Divider />}
                                 <MenuItem onClick={()=> {
                                    handleClose()
                                    action.onClick();
                                 }}>
                                    <ListItemIcon>
                                        {action.icon}
                                    </ListItemIcon>
                                    {action.featured ? <strong>{action.label}</strong> : action.label }
                                </MenuItem>
                            </>
                        );
                    })}
                </Menu>
            </Box>
        </Box>
    );
}

export default AppInterationsPageHeader;