import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  width: "90%",
  maxWidth: "800px",

  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: "24px 24px",
};

interface AppModalProps {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  handleClose: () => void;
}

const AppModal: React.FC<AppModalProps> = ({ title, isOpen, handleClose, children }) => {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography id="transition-modal-title" variant="h4" component="h2">
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: "28px" }} />
            </IconButton>

          </Box>
          <Box sx={{
            pt:2,
            maxHeight: "90vh",
            overflowY: "auto"
          }}>
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default AppModal;