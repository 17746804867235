import { createSvgIcon } from '@mui/material';


const AppOphthalmologyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" >
    <path d="M16 8C7.664 8 1.25 15.34375 1.25 15.34375L0.65625 16L1.25 16.65625C1.25 16.65625 7.098 23.3245 14.875 23.9375C15.246 23.9845 15.617 24 16 24C16.383 24 16.754 23.9845 17.125 23.9375C17.15 23.9355 17.174219 23.930734 17.199219 23.927734C17.840219 26.252734 19.993 28 22.5 28C23.6 28 24.699609 27.6 25.599609 27L30.599609 32L32 30.599609L27 25.599609C27.6 24.699609 28 23.7 28 22.5C28 21.507 27.721094 20.570766 27.246094 19.759766C29.435094 18.132766 30.75 16.65625 30.75 16.65625L31.34375 16L30.75 15.34375C30.75 15.34375 24.336 8 16 8 z M 16 10C18.203 10 20.234 10.60225 22 11.40625C22.637 12.46125 23 13.676 23 15C23 15.702 22.886406 16.373766 22.691406 17.009766C22.627406 17.007766 22.564 17 22.5 17C19.693 17 17.32425 19.189688 17.03125 21.929688C17.02925 21.951687 16.748891 21.969703 16.712891 21.970703C16.475891 21.980703 16.238 22 16 22C15.734 22 15.47675 21.98375 15.21875 21.96875C11.71075 21.58175 9 18.613 9 15C9 13.695 9.35175 12.4805 9.96875 11.4375L9.9375 11.4375C11.7185 10.6175 13.773 10 16 10 z M 16 12 A 3 3 0 0 0 13 15 A 3 3 0 0 0 16 18 A 3 3 0 0 0 19 15 A 3 3 0 0 0 16 12 z M 7.25 12.9375C7.094 13.6095 7 14.285 7 15C7 16.754 7.5 18.39525 8.375 19.78125C5.855 18.32425 4.10525 16.586 3.53125 16C4.01125 15.508 5.351 14.2035 7.25 12.9375 z M 24.75 12.9375C26.648 14.2035 27.98775 15.508 28.46875 16C28.09375 16.383 27.200688 17.260516 25.929688 18.228516C25.541687 17.914516 25.112391 17.650219 24.650391 17.449219C24.874391 16.669219 25 15.85 25 15C25 14.285 24.906 13.6025 24.75 12.9375 z M 22.5 19C24.4 19 26 20.6 26 22.5C26 24.4 24.4 26 22.5 26C20.6 26 19 24.4 19 22.5C19 20.6 20.6 19 22.5 19 z" />
  </svg>,
  'AppOphthalmology'
);

export default AppOphthalmologyIcon;