import { createSvgIcon } from '@mui/material';


const AppSmilingFaceWithHeartIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path d="M16 4C9.383 4 4 9.383 4 16C4 22.617 9.383 28 16 28C22.617 28 28 22.617 28 16C28 14.712 27.790109 13.473594 27.412109 12.308594L25.792969 13.970703C25.928969 14.626703 26 15.305 26 16C26 21.514 21.514 26 16 26C10.486 26 6 21.514 6 16C6 10.486 10.486 6 16 6C16.706 6 17.394594 6.0758437 18.058594 6.2148438C18.142594 5.5268438 18.361125 4.8853594 18.703125 4.3183594C17.833125 4.1173594 16.931 4 16 4 z M 22.75 4C21.22 4 20 5.2907813 20 6.8007812C20 7.6787813 20.505141 8.3252188 20.869141 8.6992188L24.5 12.429688L28.140625 8.6992188C28.504625 8.3262188 29 7.8087812 29 6.8007812C29 5.2907813 27.78 4 26.25 4C25.52 4 24.92 4.25 24.5 4.5C24.08 4.25 23.48 4 22.75 4 z M 22.75 6C22.989 6 23.232563 6.07375 23.476562 6.21875L24.5 6.828125L25.523438 6.21875C25.767437 6.07375 26.011 6 26.25 6C26.65 6 27 6.3747812 27 6.8007812C27 6.9877813 26.991984 7.0127344 26.708984 7.3027344L24.501953 9.5644531L22.302734 7.3046875C22.189734 7.1886875 22 6.9687812 22 6.8007812C22 6.3747812 22.35 6 22.75 6 z M 11.5 12 A 1.5 1.5 0 0 0 11.5 15 A 1.5 1.5 0 0 0 11.5 12 z M 20.5 12 A 1.5 1.5 0 0 0 20.5 15 A 1.5 1.5 0 0 0 20.5 12 z M 9 18C9 18 10.605 23 16 23C21.395 23 23 18 23 18L9 18 z" />
</svg>,
  'AppSmilingFaceWithHeart'
);

export default AppSmilingFaceWithHeartIcon;