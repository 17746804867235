import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/core/AuthProvider';
import { ROUTES } from '../constants/routes';

export interface RouteProps {
  component: React.ComponentType;
}

export const Private: React.FC<RouteProps> = ({ component: Component }) => {   

    const { user } = useAuth();
    const auth = !!user;

    return auth ? <Component /> : <Navigate to="/login" />
}

export const PublicAuth: React.FC<RouteProps> = ({ component: Component }) => {   

  const { user } = useAuth();
  const auth = !!user; 

  if(auth) return <Navigate to={ROUTES.HOME} />

  return  <Component />;
}

export default Private;