import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AppSearchIcon: React.FC<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props}>
            <svg  width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8667 0.200012C4.98792 0.200012 0.200012 4.98792 0.200012 10.8667C0.200012 16.7454 4.98792 21.5333 10.8667 21.5333C13.4231 21.5333 15.7706 20.6256 17.6104 19.1188L23.9792 25.4875C24.0775 25.5899 24.1952 25.6716 24.3255 25.7279C24.4557 25.7842 24.5959 25.814 24.7378 25.8154C24.8798 25.8169 25.0205 25.79 25.1519 25.7363C25.2833 25.6827 25.4027 25.6034 25.503 25.503C25.6034 25.4027 25.6827 25.2833 25.7363 25.1519C25.79 25.0205 25.8169 24.8798 25.8154 24.7378C25.814 24.5959 25.7842 24.4557 25.7279 24.3255C25.6716 24.1952 25.5899 24.0775 25.4875 23.9792L19.1188 17.6104C20.6256 15.7706 21.5333 13.4231 21.5333 10.8667C21.5333 4.98792 16.7454 0.200012 10.8667 0.200012ZM10.8667 2.33335C15.5927 2.33335 19.4 6.14064 19.4 10.8667C19.4 15.5927 15.5927 19.4 10.8667 19.4C6.14064 19.4 2.33335 15.5927 2.33335 10.8667C2.33335 6.14064 6.14064 2.33335 10.8667 2.33335Z" />
            </svg>
        </SvgIcon>
    )
}

export default AppSearchIcon;