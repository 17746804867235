import React from 'react';

import { Box, InputLabel, SxProps, Theme, useTheme } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import AppInputError from './components/InputErro';
import { LabelType } from './enums/LabelType';
import AppTooltip from './AppTooltip';

interface AppDatePickerProps extends DesktopDatePickerProps<Moment, false> {
  fullWidth?: boolean;
  errorMessage?: string;
  labelType?: LabelType;
  boxSx?: SxProps<Theme>;
  tooltip?: string | JSX.Element;
  tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
  optional?: boolean,
  disabled?: boolean
}

const AppDatePicker: React.FC<AppDatePickerProps> = (props) => {
    const theme = useTheme();
    const { boxSx,label, tooltip, tooltipPosition } = props;

    let labelType = props.labelType;
    const agent = navigator.userAgent;
    const isSafari = agent.includes('Safari') && !agent.includes('Chrome');

    if(labelType === null || labelType === undefined)
    {
        labelType = LabelType.FLOATING
    }

  return (
    <Box sx={{ ...boxSx }}>
      {labelType === LabelType.INLINE &&
        <Box sx={{ display: "flex" }}>
          <InputLabel sx={{ 
            color: props.optional || props.disabled ? theme.palette.text.disabled  : theme.palette.text.primary,
            fontWeight: "bold" }}>
            {label}
          </InputLabel>
          <AppTooltip
            optional={props.optional}
            position={tooltipPosition}
          >{tooltip}
          </AppTooltip>
        </Box>
      }
      <DesktopDatePicker
        {...props}
        format='DD/MM/YYYY'
        slotProps={{
          textField: {
            label: labelType === LabelType.FLOATING ?
                <InputLabel sx={{ top:0,  color: "text.primary", fontWeight: "bold" }}>
                  {label}
                </InputLabel> : "",
            sx: {

              padding: 0,
              borderRadius: 18,
              borderWidth: '0px',
              border: `0px solid ${props.errorMessage ? 'red!important' : 'grey'}`,
              width: props.fullWidth ? '100%' : 'auto',
                "legend span label.MuiFormLabel-root.MuiInputLabel-root": {
                  top: isSafari ? 0 : "8px",
                    fontSize: "12px"
                }
            }
          }
         }}
      />
      <AppInputError error={props.errorMessage} />
    </Box>
  );
}


export default AppDatePicker;