import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export interface MenuItemProps {
    icon: React.ReactNode | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    title: string;
    link: string;
    border?: "left" | "right" | "none"
}

const MenuItem = ({ icon, title, link, border = "right" }: MenuItemProps) => {

    const theme = useTheme();
    const selected = window.location.pathname.includes(link);
    
    let borderStyle = {};
    
    switch(border) {
        case "left":
            borderStyle = { borderLeft: `3px solid ${selected ? "" : "transparent"}`  }
            break;
        case "right":
            borderStyle = { borderRight: `3px solid ${selected ? "" : "transparent"}`  } 
            break;
        default:
            borderStyle = {}
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
            p: 1,
            height: "48px",
            color: selected ? theme.palette.primary.main : "#4d4d4d",
            "svg > path": {
                fill: selected ? theme.palette.primary.main : "#4d4d4d"
            },
            "svg > circle": {
                fill: selected ? theme.palette.primary.main : "#4d4d4d"
            },
            ...borderStyle
        }}>
            <Link to={link}>
                {icon}
            </Link>
            <Typography variant="body1" component="p" sx={{
                textAlign: "left",
                fontWeight: "bold",
                transition: "opacity 0.3s",
            }}>
                <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>{title}</Link>
            </Typography>
        </Box>
    );
}

export default MenuItem;