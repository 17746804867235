import { getFormattedNumber, round } from "../../../../../../utils/numberUtils";
import { BodyCompositionItemResult, MassRecomendation } from "../../types/BodyComposition/BodyCompositionItemResult";

export default class BmiService {

    private imcRecomendantion: string;
    private weight: number;
    private height: number;

    constructor(weight: number, height: number) {
        this.imcRecomendantion = "18,50 - 25,00";
        this.height = height;
        this.weight = weight;
    }

    public getBmi(): number {
        return round(this.weight / (this.height * this.height));
    }

    public getBmiBodyCompositionResult(): BodyCompositionItemResult {
        let imc = 0;

        if (this.isWeigthAndHeight()) {
            imc = this.getBmi();
        }

        let imcBodyCompositionResult = {
            id: crypto.randomUUID(),
            description: "IMC",
            massRecomendation: this.getBmiMassRecomendation(imc),
            value: imc,
            valueFormatted: getFormattedNumber(imc)
        } as BodyCompositionItemResult;

        return imcBodyCompositionResult;
    }

    public isWeigthAndHeight(): boolean {
        return this.weight > 0 && this.height > 0;
    }

    private getBmiMassRecomendation(imc: number): MassRecomendation {
        const minImc = 18.5;
        const maxImc = 25;

        let massRecomendation = {
            recomendation: this.imcRecomendantion,
            description: "Eutrofia",
            situationSeverity: "success"
        } as MassRecomendation;

        if (imc < minImc) {
            massRecomendation.description = "Desnutrição";
            massRecomendation.situationSeverity = "warning";
        }

        if (imc > maxImc) {
            massRecomendation.description = "Sobrepeso e Obesidade";
            massRecomendation.situationSeverity = "warning";
        }

        return massRecomendation;
    }
}