import { createSvgIcon } from '@mui/material';


const AppSkinIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M38.558594 4.0019531C38.497953 3.9947344 38.435047 3.998875 38.373047 4.015625C19.204047 9.060625 19.99 30.804 20 31C20 33.206 21.794 35 24 35C26.206 35 28 33.206 28 31C28 29.3 27.213672 28.283969 26.638672 27.542969C26.318672 27.129969 26.041094 26.773594 25.996094 26.433594C24.068094 11.980594 38.565891 5.021125 38.712891 4.953125C38.946891 4.843125 39.058703 4.572125 38.970703 4.328125C38.903953 4.145875 38.740516 4.0236094 38.558594 4.0019531 z M 5.5 28C3.57 28 2 29.57 2 31.5L2 41.5C2 43.43 3.57 45 5.5 45L42.5 45C44.43 45 46 43.43 46 41.5L46 31.5C46 29.57 44.43 28 42.5 28L31.5 28C30.672 28 30 28.672 30 29.5C30 29.586 30.011391 29.668 30.025391 29.75C30.011391 29.832 30 29.914 30 30C30 33.484 28.145 37 24 37C19.855 37 18 33.484 18 30C18 29.914 17.988609 29.832 17.974609 29.75C17.988609 29.668 18 29.586 18 29.5C18 28.672 17.329 28 16.5 28L5.5 28 z M 5.5 31L15.039062 31C15.435063 36.347 19.008 40 24 40C28.992 40 32.564938 36.347 32.960938 31L42.5 31C42.775 31 43 31.225 43 31.5L43 41.5C43 41.775 42.775 42 42.5 42L5.5 42C5.224 42 5 41.775 5 41.5L5 31.5C5 31.225 5.224 31 5.5 31 z" />
  </svg>,
  'AppSkin'
);

export default AppSkinIcon;