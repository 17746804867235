import { FC, useCallback } from "react";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import { FormLabel, Grid } from "@mui/material";
import BedtimeIcon from '@mui/icons-material/Bedtime';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AppRadioButton from "../../../../components/forms/AppRadioButton";
import AppTextField from "../../../../components/forms/AppTextField";
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import { SectionProps } from "./interfaces/SectionProps";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import { LifeHabitData } from "../../Stores/AnamnesisStore/types.d";
import { useAnamnesisStore } from "../../Stores/AnamnesisStore/AnamnesisStore";



const LifeHabitSection: FC<SectionProps> = () => {

  const store = useAnamnesisStore(state => state.data.lifeHabit);


  const setStateData = useAnamnesisStore(state => state.setData);


  const data = store;

  const setData = useCallback((fieldName: keyof LifeHabitData, value: any) => {
    setStateData( "lifeHabit", { ...data, [fieldName]: value });
  }, [data, setStateData]);

  return (
    <>

      <AppCheckAccordion
        title="Hábitos de Vida"
      >
        <AppFormTitleDivider text="Hábitos de Sono" icon={BedtimeIcon} iconColor={"#954ff7"} />
        <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton compact={true} boldLabel={true} label="Qualidade do Sono" name="sleepQuality"
              options={[{ label: "Dorme bem", value: "Dorme bem" }, { label: "Dorme mal", value: "Dorme mal" }]}
              value={data.sleepQuality}
              onChange={(e) => {
                setData("sleepQuality", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormLabel sx={{ color: "text.primary", fontWeight: "bold" }}>Quantidade de Sono</FormLabel>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12}>
                <AppTextField
                  name="amountSleepDay"
                  label="Horas de sono noturno" 
                  type="number"
                  value={data.amountSleepDay}
                  onChange={(e) => setData("amountSleepDay", Number.parseInt(e.target.value))}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextField
                  name="sleepTime"
                  label="Horas de sono diurno"
                  type="number"
                  value={data.amountSleepNight}
                  onChange={(e) => setData("amountSleepNight", Number.parseInt(e.target.value))}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <AppTextField
              fullWidth
              name="sleepNotes"
              label="Observações"
              labelType={LabelType.INLINE}
              multiline
              rows={4}
              value={data.sleepNotes}
              onChange={(e) => setData("sleepNotes", e.target.value)}
            />
          </Grid>
        </Grid>


        <AppFormTitleDivider text="Hábitos Alimentares" icon={EmojiFoodBeverageIcon} iconColor={"#f59f3d"} />
        <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              compact={true}
              boldLabel={true}
              label="Situação Apetite"
              name="appetite"
              options={[
                { label: "Normal", value: "Normal" },
                { label: "Crescendo", value: "Crescendo" },
                { label: "Diminuindo", value: "Diminuindo" },
              ]}
              value={data.appetite}
              onChange={(e) => {
                setData("appetite", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              compact={true}
              boldLabel={true}
              label="Modo de Mastigação"
              name="ingestionChew"
              options={[
                { label: "Normal", value: "Normal" },
                { label: "Rápida", value: "Rápida" },
                { label: "Lenta", value: "Lenta" },
              ]}
              value={data.ingestionChew}
              onChange={(e) => {
                setData("ingestionChew", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              compact={true}
              boldLabel={true}
              label="Ingestão Hídrica (diária)"
              name="amountWaterPerDay"
              options={[
                { label: "Menos que 1 litro", value: "Menos que 1 litro" },
                { label: "Entre 1 e 2 litros", value: "Entre 1 e 2 litros" },
                { label: "Entre 2 e 3 litros", value: "Entre 2 e 3 litros" },
                { label: "Mais que 3 litros", value: "Mais que 3 litros" },
              ]}
              value={data.amountWaterPerDay}
              onChange={(e) => {
                setData("amountWaterPerDay", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              compact={true}
              boldLabel={true}
              label="Restrição Alimentar"
              name="foodRestriction"
              options={[
                { label: "Nenhuma", value: "Nenhuma" },
                { label: "Vegetariano", value: "Vegetariano" },
                { label: "Vegano", value: "Vegano" },
                { label: "Outros", value: "Outros" }
              ]}
              value={data.foodRestriction}
              onChange={(e) => {
                setData("foodRestriction", e.target.value);
              }}
            />
            {
              data.foodRestriction === "Outros" && (
                <AppTextField
                  value={data.otherFoodRestriction}
                  onChange={(e) => setData("otherFoodRestriction", e.target.value)}
                  fullWidth
                  name="foodRestriction"
                  placeholder="Descreva qual é a restrição"
                  labelType={LabelType.INLINE}
                />
              )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              orientation="row"
              compact={true}
              boldLabel={true}
              label="Fumante?"
              name="smoker"
              options={[
                { label: "Sim", value: "Sim" },
                { label: "Não", value: "Não" },
              ]}
              value={data.smoker}
              onChange={(e) => {
                setData("smoker", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AppRadioButton
              orientation="row"
              compact={true}
              boldLabel={true}
              label="Bebida Alcoólica?"
              name="alcoholic"
              options={[
                { label: "Sim", value: "Sim" },
                { label: "Não", value: "Não" },
              ]}
              value={data.alcoholic}
              onChange={(e) => {
                setData("alcoholic", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppTextField
              fullWidth
              name="foodSuplementation"
              multiline
              rows={4}
              label="Suplementação alimentar"
              placeholder="Descreva se o paciente faz uso de suplementos alimentares"
              labelType={LabelType.INLINE}
              value={data.foodSuplementation}
              onChange={(e) => setData("foodSuplementation", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              fullWidth
              name="foodAllergy"
              multiline
              rows={4}
              label="Alergia alimentar"
              placeholder="Descreva se o paciente é alergico a algum tipo de alimento"
              labelType={LabelType.INLINE}
              value={data.foodAllergy}
              onChange={(e) => setData("foodAllergy", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              fullWidth
              name="foodIntolerance"
              multiline
              rows={4}
              label="Intolerância alimentar"
              placeholder="Descreva se o paciente tem intolerância a algum tipo de alimento"
              labelType={LabelType.INLINE}
              value={data.foodIntolerance}
              onChange={(e) => setData("foodIntolerance", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              fullWidth
              name="foodAversion"
              multiline
              rows={4}
              label="Tem aversão a algum alimento?"
              placeholder="Descreva se o paciente tem aversão a algum tipo de alimento"
              labelType={LabelType.INLINE}
              value={data.foodAversion}
              onChange={(e) => setData("foodAversion", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AppTextField
              fullWidth
              name="foodHabitsNotes"
              multiline
              rows={4}
              label="Notas gerais sobre hábitos alimentares"
              labelType={LabelType.INLINE}
              value={data.foodNotes}
              onChange={(e) => setData("foodNotes", e.target.value)}
            />
          </Grid>
        </Grid>

        <AppFormTitleDivider text="Exercícios Físicos" icon={FitnessCenterIcon} iconColor={"#3868c2"} />
        <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton
              compact={true}
              boldLabel={true}
              label="Frequência"
              name="exerciseFrequency"
              options={[
                { label: "1 a 2 dias na semana", value: "1 a 2 dias na senama" },
                { label: "3 a 4 dias na semana", value: "3 a 4 dias na semana" },
                { label: "5 ou mais dias na semana", value: "5 ou mais dias na semana" },
                { label: "Não pratica com frequência", value: "Não pratica com frequência" },
              ]}
              value={data.exerciseFrequency}
              onChange={(e) => {
                setData("exerciseFrequency", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <AppTextField
              fullWidth
              name="exerciseNotes"
              multiline
              rows={6}
              label="Observações"
              labelType={LabelType.INLINE}
              value={data.exerciseNotes}
              onChange={(e) => setData("exerciseNotes", e.target.value)}
            />
          </Grid>
        </Grid>

        <AppFormTitleDivider text="Hábitos de Compra e Consumo" icon={LocalGroceryStoreIcon} iconColor={"#72ad2a"} />

        <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>

          <Grid item xs={12} sm={6} md={4}>
            <AppTextField
              name="peopleLivingTogether"
              type="number"
              label="Quantas pessoas moram na casa?"
              value={data.peopleLivingTogether}
              onChange={(e) => setData("peopleLivingTogether", e.target.value)}
              labelType={LabelType.INLINE}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppRadioButton compact={true} boldLabel={true} label="Quem realiza as compras" name="whoBuys"
              options={[
                { label: "Paciente", value: "Paciente" },
                { label: "Outra pessoa", value: "Outra pessoa" },
              ]}
              value={data.whoBuys}
              onChange={(e) => {
                setData("whoBuys", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <AppTextField
              fullWidth
              name="whereBuys"
              type="text"
              label="Onde realiza as compras?"
              labelType={LabelType.INLINE}
              value={data.whereBuys}
              onChange={(e) => setData("whereBuys", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppTextField
              name="timesOfBuysInMonth"
              type="number"
              label={"Quantas vezes no mês?"}
              labelType={LabelType.INLINE}
              value={data.timesOfBuysInMonth}
              onChange={(e) => setData("timesOfBuysInMonth", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppTextField
              name="oilLiterMonth"
              type="number"
              label="Litros de Óleo/Mês"
              labelType={LabelType.INLINE}
              value={data.oilLiterMonth}
              onChange={(e) => setData("oilLiterMonth", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTextField
              name="kgSaltMonth"
              type="number"
              label="Kg de Sal/Mês"
              labelType={LabelType.INLINE}
              value={data.kgSaltMonth}
              onChange={(e) => setData("kgSaltMonth", e.target.value)}

            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppTextField
              name="kgSuggarMonth"
              type="number"
              label="Kg de Açúcar/Mês"
              labelType={LabelType.INLINE}
              value={data.kgSuggarMonth}
              onChange={(e) => setData("kgSuggarMonth", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <AppTextField
              fullWidth
              name="buyNotes"
              multiline
              rows={4}
              label="Observações"
              labelType={LabelType.INLINE}
              value={data.buyNotes}
              onChange={(e) => setData("buyNotes", e.target.value)}
            />
          </Grid>
        </Grid>

      </AppCheckAccordion>
    </>
  );
}

export default LifeHabitSection;