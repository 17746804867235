import { Gender } from "../../../../../../domain/interfaces/Patient";
import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { AnthropometricCaculateType } from "../types";
import SkinfoldProtocolServiceBase from "./SkinfoldProtocolServiceBase";
import { ISkinfoldProtocolCalculate } from "./types";

type CalculateProtocol = {
    [key: string]: (sumOfSkinfold: number) => number
}

export default class FourSkinfoldDurninAndWomersleyService extends SkinfoldProtocolServiceBase implements ISkinfoldProtocolCalculate {

    public skinfoldProtocolType: SkinfoldProtocolType = SkinfoldProtocolType.FourSkinfoldDurninAndWomersley;

    private maleCaculatesProtocol: CalculateProtocol = {
        "17": (sumOfSkinfold: number): number => {
            return 1.1533 - (0.0643 * Math.log(sumOfSkinfold)) 
        },
        "19": (sumOfSkinfold: number): number => {
            return 1.1620 - (0.0630 * Math.log(sumOfSkinfold)) 
        },
        "29": (sumOfSkinfold: number): number => {
            return 1.1631 - (0.0632 * Math.log(sumOfSkinfold)) 
        },
        "39": (sumOfSkinfold: number): number => {
            return 1.1422 - (0.0544 * Math.log(sumOfSkinfold)) 
        },
        "49": (sumOfSkinfold: number): number => {
            return 1.1620 - (0.0700 * Math.log(sumOfSkinfold)) 
        },
        "170": (sumOfSkinfold: number): number => {
            return 1.1715 - (0.0779 * Math.log(sumOfSkinfold)) 
        }
    }

    private femaleCaculatesProtocol: CalculateProtocol = {
        "17": (sumOfSkinfold: number): number => {
            return 1.1369 - (0.0598 * Math.log(sumOfSkinfold)) 
        },
        "19": (sumOfSkinfold: number): number => {
            return 1.1549 - (0.0678 * Math.log(sumOfSkinfold)) 
        },
        "29": (sumOfSkinfold: number): number => {
            return 1.1599 - (0.0717 * Math.log(sumOfSkinfold)) 
        },
        "39": (sumOfSkinfold: number): number => {
            return 1.1423 - (0.0632 * Math.log(sumOfSkinfold)) 
        },
        "49": (sumOfSkinfold: number): number => {
            return 1.1333 - (0.0612 * Math.log(sumOfSkinfold)) 
        },
        "170": (sumOfSkinfold: number): number => {
            return 1.1339 - (0.0645 * Math.log(sumOfSkinfold)) 
        }
    }

    constructor(anthopometricCalculate: AnthropometricCaculateType) {
        super(anthopometricCalculate);
        this.sumOfSkinfold = this.getSumOfSkinFolds();

        this.isValid = this.isAllMeasures() && this.isWeigthAndHeight();
    }  
    
    public calculate(): BodyComposition {
        if(!this.isValid) {
            return this.getDefaultBodyComposition();
        }
        
        let bodyDensity: number;         
            
        if (this.gender === Gender.Male) {
            bodyDensity = this.caculateProtocol(this.maleCaculatesProtocol);
        } else {
            bodyDensity = this.caculateProtocol(this.femaleCaculatesProtocol);
        }

        return this.getBodyComposition(bodyDensity);
    }

    private caculateProtocol(calculateProtocol: CalculateProtocol): number {
        const objectKeys = Object.keys(calculateProtocol);

        for(let i = 0; i < objectKeys.length; i++) {
            let key = objectKeys[i];
            const parameterAge = Number(key);

            if(this.age < parameterAge) {
                const caculateProtocol = calculateProtocol[key];
                return caculateProtocol(this.sumOfSkinfold);
            }
        };

        throw new Error("Idade não mapeada");
    }

    public isAllMeasures(): boolean {
        return this.isAllMeasuresWithType(this.skinfoldProtocolType);
    }

}