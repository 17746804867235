import React from 'react';
import { Clinic } from '../../../domain/interfaces/Clinic';
import ClinicForm from './ClinicForm';


interface NewClinicFormProps {
  isProcessing?: boolean;
  onSubmit: (clinic: Clinic) => void;
}

const initialData: Clinic = {
  name: "",
  description: "",
  address: {
    city: "",
    state: "",
    country: "Brasil",
    postalCode: "",
    street: "",
    number: "",
  }
}

const NewClinicForm: React.FC<NewClinicFormProps> = (props) => {
  
  return ( 
    <ClinicForm initialClinicData={initialData} onSubmit={props.onSubmit} isDisabled={props.isProcessing} />
  );
}

export default NewClinicForm;