import { createSvgIcon } from '@mui/material';


const AppHeartIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <path d="M8.125 3.875C6.398438 3.875 4.828125 4.472656 3.71875 5.5625C2.609375 6.652344 2 8.210938 2 10C2 13.027344 3.855469 15.285156 5.96875 17.1875C8.082031 19.089844 10.546875 20.773438 12.21875 22.75C12.402344 22.980469 12.675781 23.117188 12.96875 23.125L13.03125 23.125C13.324219 23.117188 13.597656 22.980469 13.78125 22.75C15.453125 20.773438 17.917969 19.089844 20.03125 17.1875C22.144531 15.285156 24 13.027344 24 10C24 8.210938 23.359375 6.652344 22.25 5.5625C21.140625 4.472656 19.601563 3.875 17.875 3.875C16.164063 3.875 14.761719 4.808594 13.78125 5.65625C13.300781 6.070313 13.277344 6.183594 13 6.5C12.726563 6.183594 12.699219 6.070313 12.21875 5.65625C11.242188 4.808594 9.835938 3.875 8.125 3.875 Z M 8.125 5.875C9.066406 5.875 10.117188 6.503906 10.90625 7.1875C11.695313 7.871094 12.1875 8.53125 12.1875 8.53125C12.375 8.792969 12.679688 8.949219 13 8.949219C13.320313 8.949219 13.625 8.792969 13.8125 8.53125C13.8125 8.53125 14.300781 7.871094 15.09375 7.1875C15.886719 6.503906 16.929688 5.875 17.875 5.875C19.160156 5.875 20.171875 6.3125 20.875 7C21.578125 7.6875 22 8.660156 22 10C22 12.214844 20.660156 13.910156 18.6875 15.6875C16.941406 17.261719 14.808594 18.875 13 20.78125C11.1875 18.867188 9.0625 17.261719 7.3125 15.6875C5.339844 13.910156 4 12.214844 4 10C4 8.660156 4.421875 7.6875 5.125 7C5.828125 6.3125 6.835938 5.875 8.125 5.875Z" />
  </svg>,
  'AppHeart'
);

export default AppHeartIcon;