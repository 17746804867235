import React, { useCallback, useMemo, useRef } from 'react';
import { Box, FormLabel, OutlinedInputProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LabelType } from './enums/LabelType';
import AppInputError from './components/InputErro';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import AppTooltip from './AppTooltip';

interface AppFloatNumberFieldProps extends OutlinedInputProps {
  name: string,
  id?: string,
  label?: string,
  value?: number,
  labelType?: LabelType,
  setFormErros?: any;
  formErros?: any;
  prefix?: string,
  suffix?: string,
  placeholder?: string,
  onValueChange?: (value: Number) => void,
  onRawValueChange?: (value: string) => void,
  optional?: boolean,
  maxValue?: number,
  minValue?: number
  errorMessage?: string;
  decimalLimit?: number; // how many digits allowed after the decimal
  allowNegative?: boolean;
  tooltip?: string | JSX.Element;
  tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
}

const AppMaskedNumberField: React.FC<AppFloatNumberFieldProps> = (props) => {

  let {
    name,
    sx,
    label,
    labelType,
    errorMessage,
    type,
    id,
    prefix,
    suffix,
    placeholder,
    disabled,
    value,
    fullWidth,
    optional: isNotUsed,
    maxValue,
    minValue,
    readOnly,
    onBlur,
    decimalLimit,
    tooltipPosition,
    ...rest } = props;

  const theme = useTheme();

  let hasError = errorMessage !== undefined && errorMessage !== null && errorMessage !== "";
  const labelColor = (disabled || isNotUsed) ? theme.palette.text.disabled : theme.palette.text.primary;
  const inputRef = useRef<HTMLInputElement | null>(null);


  const inputStyle =  {
    paddingLeft: "4px",
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: labelColor
  }

  const boxStyle = useMemo(() => ({
    pointerEvents: disabled ? "none" : "auto",
    border: `1px solid ${hasError ? "#E17373" : "#ccc"}`,
    position: "relative",
    padding: "9px",
    display: "inline-flex",
    justifyContent: "space-between",
    borderRadius: 3,
    width: fullWidth ? "100%" : "auto",
    color: theme.palette.text.primary,
    "&:focus-within": {
      border: `1px solid ${hasError ? "#E17373" : theme.palette.primary.main}!important`,
      outline: `1px solid ${hasError ? "#E17373" : theme.palette.primary.main}`,
      color: `${theme.palette.primary.main} !important`,
    },
    "&:hover": {
      border: `1px solid ${hasError ? "#E17373" : "#000"}`,
    }
  }), [hasError, theme.palette.primary.main, disabled, fullWidth]);

  const isNumberAllowed = useCallback((values: NumberFormatValues): boolean => {
    const { floatValue: value } = values;
    if (maxValue === null && minValue === null) {
      return true;
    }

    if (value === undefined) return true;

    if (maxValue !== undefined && value > maxValue) {
      return false
    }

    if (minValue !== undefined && value < minValue) {
      return false
    }

    return true;
  }, [maxValue, minValue])

  const handleOnValueChange = useCallback((event: any) => {
    const { floatValue: valueNew } = event;

    if (rest.onValueChange) {
      rest.onValueChange(valueNew);
    }

    if (props.onRawValueChange) {
      props.onRawValueChange(valueNew);
    }

    if (inputRef.current) {
      if (value === 0) {
        inputRef.current.setSelectionRange(1, 1);
      }
    }

  }, [rest, props, value]);

  if (!labelType) {
    labelType = LabelType.FLOATING;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {labelType === LabelType.INLINE &&
        <Box sx={{ display: "flex", height: "24px" }}>
            <FormLabel id={`row-radio-buttons-group-${props.name}`} sx={{
              color: labelColor,
              fontWeight: "normal",
              ml: 1
            }}>
              {props.label}
            </FormLabel>
          <AppTooltip
            position={props.tooltipPosition}
            optional={props.optional}
          >{props.tooltip}
          </AppTooltip>
        </Box>
      }
      <Box sx={boxStyle}>
        {labelType === LabelType.FLOATING &&
          <Typography sx={{
            position: "absolute",
            top: "-5px",
            fontSize: "10px",
            fontFamily: theme.typography.fontFamily,
            backgroundColor: "#fff",
            padding: "0 4px",
            color: labelColor,
            fontWeight: "bold"
          }}>
            {label}
          </Typography>
        }
        <NumericFormat
          disabled={disabled}
          id={id}
          value={value}
          onValueChange={handleOnValueChange}
          onBlur={onBlur}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimalLimit ?? 2}
          fixedDecimalScale={true}
          allowNegative={false}
          placeholder={disabled ? "" : placeholder ?? ""}
          style={inputStyle}
          getInputRef={(ref: HTMLInputElement) => (inputRef.current = ref)}
          isAllowed={isNumberAllowed}
          width="100%"
        />
        {suffix !== undefined &&
          <span style={{ fontSize: ".9rem", margin: 0, padding: 0, color: labelColor }}>{suffix}</span>
        }
      </Box>
      <AppInputError error={errorMessage} />
    </Box>
  );
}

export default AppMaskedNumberField;