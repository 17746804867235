import { createSlice } from '@reduxjs/toolkit';

export interface LayoutState {
    windowSize: {
        width: number;
        height: number;
        isMobile: boolean;
        isTablet: boolean;
    }
}

const estadoInicial = {
    windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= 600,
        isTablet: window.innerWidth <= 960
    },
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState: estadoInicial,
    reducers: {
        setWindowSize: (state, action) => {
            const isMobile = window.innerWidth <= 600;
            const isTablet = window.innerWidth <= 960;
           
            state.windowSize = {
              ...action.payload.windowSize,
              isMobile,
              isTablet
            };
        }
    }
});

export const { setWindowSize } = layoutSlice.actions;

export default layoutSlice.reducer;