import { Delete, Edit, Visibility } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ACTIONS } from '../../../constants/routes';

export interface AppTableCrudActionsProps {
  actions: AppTableCrudAction[];
  data: any;
  basePath: string;
}

export interface AppTableCrudAction {
  action: AppTableCrudActionsEnum;
  title?: string;
  onClick?: ((data: any) => void) | ((data: any) => Promise<void>);
}

export enum AppTableCrudActionsEnum {
  EDIT = "edit",
  VIEW = "view",
  DELETE = "delete"
}


const AppTableCrudActions: React.FC<AppTableCrudActionsProps> = ({ actions, data, basePath }) => {

  const navigate = useNavigate();


  return (
    <Stack sx={{ justifyContent: "flex-start" }} direction="row" spacing={1}>
      {actions.map((action, index) => {

        return (
          <IconButton key={`action-${index}`} title={action.title} onClick={() => {

            action.onClick && action.onClick(data);

            switch (action.action) {
              case AppTableCrudActionsEnum.EDIT:
                navigate(`${basePath}/${data.id}/${ROUTE_ACTIONS.EDIT}`);
                break;
              case AppTableCrudActionsEnum.VIEW:
                navigate(`${basePath}/${data.id}/${ROUTE_ACTIONS.VIEW}`);
                break;
            }
          }}>
            {action.action === AppTableCrudActionsEnum.EDIT && <Edit />}
            {action.action === AppTableCrudActionsEnum.VIEW && <Visibility />}
            {action.action === AppTableCrudActionsEnum.DELETE && <Delete />}
          </IconButton>
        );

      })}
    </Stack>
  );
}


export default AppTableCrudActions;