import {create } from 'zustand';
import { AnthropometricStore } from './types';
import { Anthropometric } from '../../Interations/Anthropometric/types/Anthropometric';
import { getDefaultAnthropometric } from '../../Interations/Anthropometric/initialDataForm/bodyCompositionInitialData';

export const useAnthropometricStore = create<AnthropometricStore>((set) => ({
    data: getDefaultAnthropometric(),
    setData: (propName: keyof Anthropometric, data: any) => {
        set((state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    [propName]: data
                }
            };
        });
    },
    setAnthropometricData: (data: Anthropometric) => {
        set((state) => {
            return {data};
        });
    }
}));