import React from 'react';
import { AuthProvider } from './AuthProvider';
import { ToastProvider } from './AppToastContextProvider';
import AppDialogProvider from './AppDialog/AppDialogProvider';

interface Props {
    children: React.ReactElement
}


const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <AuthProvider>
        <ToastProvider>
          <AppDialogProvider>
          {children}
          </AppDialogProvider>
        </ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;