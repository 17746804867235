import React, { useState, createContext, useContext } from 'react';
import AppDialog from './AppDialog';
import { AppDialogAlertProps, AppDialogConfirmProps, AppDialogContextData, AppDialogLoadingProps, AppDialogProviderProps } from './types';


const AppDialogContext = createContext<AppDialogContextData>({} as AppDialogContextData);

export const useDialog = () => {
    return useContext(AppDialogContext);
};

const AppDialogProvider: React.FC<AppDialogProviderProps> = ( props ) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [title, setTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    const [dialogResolve, setDialogResolve] = useState() as any | null;
    const [confirmActionText, setConfirmActionText] = useState('Confirmar');
    const [cancelActionText, setCancelActionText] = useState('Cancelar');
    const [invert, setInvert] = useState(false);
    const [severity, setSeverity] = useState("info" as "success" | "info" | "warning" | "error");

    const confirm = async (confirmProps: AppDialogConfirmProps) => {
      const { message, title, confirmActionText = "Confirmar", cancelActionText = "Cancelar", invert} = confirmProps;
      return new Promise<void>((resolve) => { // Change the return type of the Promise to void
        setDialogType('confirm');
        setDialogMessage(message);
        setTitle(title);
        setDialogOpen(true);
        setInvert(invert || false);
        setDialogResolve(() => resolve);
        setConfirmActionText(confirmActionText);
        setCancelActionText(cancelActionText);
      });

    };

    const alert = async (props: AppDialogAlertProps) => {
        const { message, title, invert, severity } = props;
        return new Promise((resolve) => {
            setDialogType('alert');
            setDialogMessage(message);
            setTitle(title);
            setInvert(invert || false);
            setDialogOpen(true);
            setDialogResolve(() => resolve);
            setSeverity(severity || "info");

        });
    };
    
    const handleClose = (confirmed: boolean) => {
        setDialogOpen(false);
        if (dialogResolve) {
            dialogResolve(confirmed);
            setDialogResolve(null);
        }
    };

    const loading = (props: AppDialogLoadingProps) => {
        const { loading } = props; 
        setDialogOpen(loading);
        setDialogType('loading');
        setTitle("Processando");
        setDialogMessage('Por favor, aguarde...');
    };

    return (
        <>
            <AppDialog
                open={dialogOpen}
                confirmActionText={confirmActionText}
                cancelActionText={cancelActionText}
                onClose={handleClose}
                message={dialogMessage}
                type={dialogType}
                title={title}
                invert={invert}
                severity={severity}
            />
            <AppDialogContext.Provider value={{ confirm, alert, loading } as unknown as AppDialogContextData}>
                {props.children}
            </AppDialogContext.Provider>
        </>
    );
};


export default AppDialogProvider;
