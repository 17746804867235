import React from 'react';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LayoutState, setWindowSize } from '../../state/reducers/layout.reducer';

// import dispatch from redux
import { useDispatch, useSelector } from 'react-redux';


import AppSideBar from './AppSideBar';
import AppTopBar from './AppTopBar';

interface Props {
    children?: React.ReactNode | React.ReactNode[];
}

export interface WindowSize {
    width: number;
    height: number;
}

const AppMainLayout: React.FC<Props> = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const layout = useSelector((state: any) => state.layout as LayoutState);

  const { isMobile } = layout.windowSize;
  
  React.useEffect(() => {
    const handleResize = () => {
      dispatch(setWindowSize({ windowSize: { width: window.innerWidth, height: window.innerHeight } }));
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); 
 
  return (
    <Box sx={{backgroundColor: theme.palette.background.default }}>
      <AppTopBar />
      <Container maxWidth="xl"
       sx={{
        height:"auto", 
        minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        p: isMobile ? 0 : 4, 
        display:"flex"}}
      >
        <AppSideBar />
        <Box sx={{
          backgroundColor:"#e4e4e4", 
          height:"auto",
          flexGrow:1,
          borderTopRightRadius: isMobile ? 0 : 32,
          borderBottomRightRadius: isMobile ? 0 : 32,
          padding: isMobile ? 1 : 4,
          }}>
          {props.children}
        </Box>
      </Container>
    </Box>
  );
};

export default AppMainLayout;