import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AppRulerIcon: React.FC<SvgIconProps> = (props) => {

    return (
        <SvgIcon {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">                
                <path d="M177.9 494.1c-18.7 18.7-49.1 18.7-67.9 0L17.9 401.9c-18.7-18.7-18.7-49.1 0-67.9l50.7-50.7 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 50.7-50.7c18.7-18.7 49.1-18.7 67.9 0l92.1 92.1c18.7 18.7 18.7 49.1 0 67.9L177.9 494.1z" fill="rgb(246,159,66)"/>
            </svg>
        </SvgIcon>
    )
}

export default AppRulerIcon;