import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import FourSkinfoldDurninAndWomersleyService from "../SkinfoldProtocols/FourSkinfoldDurninAndWomersleyService";
import SevenSkinfoldJacksonAndPollockAndWardService from "../SkinfoldProtocols/SevenSkinfoldJacksonAndPollockAndWardService";
import ThreeSkinfoldGuedesService from "../SkinfoldProtocols/ThreeSkinfoldGuedesService";
import ThreeSkinfoldJacksonAndPollockAndWardService from "../SkinfoldProtocols/ThreeSkinfoldJacksonAndPollockAndWardService";
import { AnthropometricCaculateType, IAnthropometricProtocolService } from "../types";

export function skinfoldProtocolFactory(skinfoldProtocolType: SkinfoldProtocolType, anthropometricCalculate: AnthropometricCaculateType): IAnthropometricProtocolService {
    switch (skinfoldProtocolType) {
        case SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard:
            return new SevenSkinfoldJacksonAndPollockAndWardService(anthropometricCalculate);
        case SkinfoldProtocolType.ThreeSkinfoldGuedes:
            return new ThreeSkinfoldGuedesService(anthropometricCalculate);
        case SkinfoldProtocolType.FourSkinfoldDurninAndWomersley:
            return new FourSkinfoldDurninAndWomersleyService(anthropometricCalculate);
        case SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard:
            return new ThreeSkinfoldJacksonAndPollockAndWardService(anthropometricCalculate);
        default:
            throw new Error("Tipo de protocolo inválido");
    }
}