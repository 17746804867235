import { Grid } from '@mui/material';
import React from 'react';
import AppButton from '../../../components/forms/AppButton';
import AppErrorList from '../../../components/forms/AppErrorList';
import { Check } from '@mui/icons-material';
import AppMaskedTextField from '../../../components/forms/AppMaskedTextField';
import AppSelect, { AppSelectOption } from '../../../components/forms/AppSelect';
import AppTextField from '../../../components/forms/AppTextField';
import FormService, { FormErrors } from '../../../services/form.service';
import { Clinic } from '../../../domain/interfaces/Clinic';
import AddressService from '../../../services/address.service';
import * as Yup from 'yup';

const formSchema = Yup.object({
  name: Yup
    .string()
    .required('Obrigatório informar o nome.'),
});

export interface ClinicFieldsProps {
  isDisabled?: boolean;
  initialClinicData: Clinic;
  onClean?: () => void;
  onSubmit: (clinic: Clinic) => void;
}


const ClinicForm: React.FC<ClinicFieldsProps> = ({ initialClinicData, isDisabled, onClean, onSubmit }) => {

  const [cities, setCities] = React.useState([] as AppSelectOption[]);
  const [states, setStates] = React.useState([] as AppSelectOption[]);

  const [formErrors, setFormErrors] = React.useState({} as FormErrors);
  const [erros, setErros] = React.useState([] as string[]);
  const [clinicData, setClinicData] = React.useState(initialClinicData);

  const formService = new FormService(clinicData, setClinicData, setErros, setFormErrors);


  const handleSubmit = async () => {
    try {
      setFormErrors({});

      await formSchema.validate(clinicData, { abortEarly: false })

      onSubmit(clinicData);

    } catch (errors) {
      formService.handleErros(errors);
    }
  }

  const handleClean = () => {
    setClinicData(initialClinicData);
    if (onClean) {
      onClean();
    }
  }

    

  React.useEffect(() => {
    const fetchAddress = async (postalCode: string) => {
      const addressData = await AddressService.getAddressByPostalCode(postalCode);
      console.log(addressData);
      if (addressData) {
        setClinicData({
          ...clinicData,
          address: {
            number: "",
            postalCode: postalCode,
            country: 'Brasil',
            city: addressData.localidade,
            state: addressData.uf,
            street: addressData.logradouro,
          }
        });
      }
    }
    // remove all non numeric characters
    const postalCode = clinicData.address?.postalCode.replace(/\D/g, '')!;
    if (postalCode.length === 8) {
      fetchAddress(postalCode);
    }


  }, [clinicData.address?.postalCode]);

  React.useEffect(() => {
    const getStates = async () => {
      const states = await AddressService.getStatesByCountry(clinicData.address!.country);

      const statesOptions = states.map((state) => {
        return { value: state, label: state } as AppSelectOption;
      });

      setStates(statesOptions);
    }

    if (clinicData.address?.country) {
      getStates();
    }

  }, [clinicData.address?.country]);


  React.useEffect(() => {
    const getCities = async () => {
      const cities = await AddressService.getCitiesByState(clinicData.address!.state);

      const citiesOptions = cities.map((city) => {
        return { value: city, label: city } as AppSelectOption;
      });

      setCities(citiesOptions);
    }

    if (clinicData.address?.state) {
      getCities();
    }

  }, [clinicData.address?.state]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={5} xs={6}>
          <AppTextField
            fullWidth
            name="name"
            type="text"
            disabled={isDisabled}
            autoFocus={true}
            label="Nome do consultório*"
            value={clinicData.name}
            onChange={(e) => formService.setInputValue("name", e.target.value)}
            errorMessage={formErrors.name}
          />
        </Grid>

        <Grid item md={7} xs={6}>
          <AppTextField
            fullWidth
            name="description"
            disabled={isDisabled}
            type="text"
            autoFocus={true}
            label="Descrição"
            value={clinicData.description}
            onChange={(e) => formService.setInputValue("description", e.target.value)}
            errorMessage={formErrors.description}
          />
        </Grid>

        <Grid item md={3} xs={3}>
          <AppMaskedTextField
            id="postalCode"
            fullWidth
            disabled={isDisabled}
            mask="99999-999"
            label="Cep"
            name="postalCode"
            value={clinicData.address?.postalCode}
            onChange={(e) => formService.setInputValue("address", { ...clinicData.address, postalCode: e.target.value })}
          />
        </Grid>

        <Grid item md={2} xs={3}>
          <AppSelect
            fullWidth
            name="state"
            disabled={isDisabled}
            label="Estado"
            value={clinicData.address?.state}
            onChange={(e) => formService.setInputValue("address", { ...clinicData.address, state: e.target.value })}
            options={states}
          />
        </Grid>

        <Grid item md={7} xs={6}>
          <AppSelect
            fullWidth
            name="city"
            disabled={isDisabled}
            label="Cidade"
            value={clinicData.address?.city}
            onChange={(e) => formService.setInputValue("address", { ...clinicData.address, city: e.target.value })}
            options={cities}
          />
        </Grid>

        <Grid item md={8} xs={9}>
          <AppTextField
            fullWidth
            name="street"
            disabled={isDisabled}
            type="text"
            autoFocus={true}
            label="Logradouro"
            value={clinicData.address?.street}
            onChange={(e) => formService.setInputValue("address", { ...clinicData.address, street: e.target.value })}
          />
        </Grid>

        <Grid item md={4} xs={3}>
          <AppTextField
            fullWidth
            name="number"
            disabled={isDisabled}
            type="text"
            autoFocus={true}
            label="Número"
            value={clinicData.address?.number}
            onChange={(e) => formService.setInputValue("address", { ...clinicData.address, number: e.target.value })}
          />
        </Grid>

      </Grid>

      <AppErrorList errors={erros} />

      <Grid
        container
        sx={{mt:0}}
        spacing={2}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
      >
        <Grid item>
          <AppButton
            color="inherit"
            variant='outlined'
            disabled={isDisabled}
            label="Limpar Campos"
            onClick={handleClean}
            fullWidth={false}
          />
        </Grid>
        <Grid item>
          <AppButton
            variant='contained'
            disabled={isDisabled}
            startIcon={<Check />}
            label={"Salvar Consultório"}
            onClick={handleSubmit}
            fullWidth={false}
          />
        </Grid>

      </Grid>
    </>
  );
}

export default ClinicForm;