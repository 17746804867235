
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, SxProps } from "@mui/material";
import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

export interface AppTableCellProps {
    align: "right" | "left" | "center" | undefined;
    maxWidth?: string;
    expansible?: boolean;
    expansibleTitle?: string;
    children: React.ReactNode;
}
const AppTableCell: React.FC<AppTableCellProps> = ({ align, maxWidth, expansible, expansibleTitle, children }) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    let flexAlign: string = align || "flex-start";

    if (align === "right")
        flexAlign = "flex-end";
    if (align === "left")
        flexAlign = "flex-start";

    const normalStyle = {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: flexAlign,
        pl: 1,
        pr: 2,
        width: "100%",
        maxWidth: maxWidth || "100%",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    } as SxProps;

    return (<>
        {expansible &&

            <Accordion 
            sx={{ width: "calc(100% - 8px)", border: "none" }}
            onChange={() => setExpanded(!expanded)}
            expanded={expanded}
             >
                <AccordionSummary expandIcon={ expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}>
                    <Typography>{ expansibleTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        }
        {!expansible && <Box className={"nutrifit-app-table-cell"} sx={normalStyle}>{children}</Box>}
    </>);
}

export default AppTableCell;