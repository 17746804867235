import { Gender } from "../../../../../../domain/interfaces/Patient";
import AnthropometricService from "../../../../../../services/anthropometric.services";
import { Anthropometric } from "../../types/Anthropometric";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { BodyCompositionItemResult } from "../../types/BodyComposition/BodyCompositionItemResult";
import { AnthropometricChildCaculateType } from "../types";

export type WeltmanProtocolCalculateType = {
    weight: number;
    height: number;
    data: BodyComposition;
    birthDate: string;
    gender: Gender;
}

export default class ChildAnthropometricService {
    
    private anthropometricCalculate: AnthropometricChildCaculateType;

    constructor(anthropometricCalculate: AnthropometricChildCaculateType) {
        this.anthropometricCalculate = anthropometricCalculate;
    }
    
    public async calculate(): Promise<Anthropometric> {
        const { bodyCompositionResult } = this.anthropometricCalculate.data;
        if(!this.isAllMeasures()){
            return {
                bodyComposition: {
                    ...this.anthropometricCalculate.data,
                    bodyCompositionResult: {
                        id: bodyCompositionResult.id,
                        results: [] as BodyCompositionItemResult[]

                    }
                }
            } as Anthropometric;
        }

        return await this.getAnthropometricResults();
    }

    public isAllMeasures(): boolean {
        const { weight, height } = this.anthropometricCalculate;
        return weight > 0 && height > 0;
    }

    public static isAllMeasures(weight: number, height: number): boolean {
        return weight > 0 && height > 0;
    }
    
    private async getAnthropometricResults() : Promise<Anthropometric> {  
        const { data: bodyComposition } = this.anthropometricCalculate;
        
        const result = await AnthropometricService.calculateChildAnthropometricResult(this.anthropometricCalculate);

        return {
            ...result,
            bodyComposition: {
                ...result.bodyComposition,
                id: bodyComposition.id,
                bodyCompositionResult: {
                    ...result.bodyComposition.bodyCompositionResult,
                    id: bodyComposition.bodyCompositionResult.id
                } 
            }
        } as Anthropometric;
    }
}