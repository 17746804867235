import React from 'react';

const HydrationTable: React.FC = () => {
    const tableStyle: React.CSSProperties = {
        borderCollapse: 'collapse',
        width: '200px',
        margin: '8px',
    };

    const tdStyle: React.CSSProperties = {
        padding: '10px',
        textAlign: 'center',
    };

    const squareStyle: React.CSSProperties = {
        width: '20px',
        height: '20px',
        display: 'inline-block',
    };

    const yellowLight: React.CSSProperties = {
        backgroundColor: '#fff9c4', // Amarelo claro
    };

    const yellowMedium: React.CSSProperties = {
        backgroundColor: '#ffd54f', // Amarelo médio
    };

    const yellowDark: React.CSSProperties = {
        backgroundColor: '#ff8f00', // Amarelo escuro
    };

    return (
        <table style={tableStyle}>
            <tbody>
                <tr>
                    <td style={tdStyle}>
                        <div style={{ ...squareStyle, ...yellowLight }}></div>
                    </td>
                    <td style={tdStyle} align='left'>Bem Hidratado</td>
                </tr>
                <tr>
                    <td style={tdStyle}>
                        <div style={{ ...squareStyle, ...yellowMedium }}></div>
                    </td>
                    <td style={tdStyle} align='left'>Mal Hidratado</td>
                </tr>
                <tr>
                    <td style={tdStyle}>
                        <div style={{ ...squareStyle, ...yellowDark }}></div>
                    </td>
                    <td style={tdStyle} align='left'>Desidratado</td>
                </tr>
            </tbody>
        </table>
    );
};

export default HydrationTable;
