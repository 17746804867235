import { Gender } from "../../../../../../domain/interfaces/Patient";
import { round } from "../../../../../../utils/numberUtils";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { BodyCompositionItemResult } from "../../types/BodyComposition/BodyCompositionItemResult";
import AnthropometricProtocolService  from "../AnthropometricProtocolService";
import { IAnthropometricProtocolService } from "../types";

export type WeltmanProtocolCalculateType = {
    weight: number;
    height: number;
    data: BodyComposition;
    birthDate: string;
    gender: Gender;
}

export default class WeltmanProtocolService extends AnthropometricProtocolService implements IAnthropometricProtocolService {
    
    public calculate(): BodyComposition {
        if(!this.isAllMeasures()) {
            return this.getDefaultBodyComposition();
        }
        
        let fatMassPercentage: number;         
            
        if (this.gender === Gender.Male) {
            fatMassPercentage = this.maleCaculate();
        } else {
            fatMassPercentage = this.femaleCaculate();
        }

        return this.getBodyComposition(fatMassPercentage);
    }

    public isAllMeasures(): boolean {
        const { weltmanProtocol } = this.anthropometricCalculate.data;
        if(weltmanProtocol === undefined) return false;
        const { abdomen1, abdomen2 } = weltmanProtocol;
        return abdomen1 > 0 && abdomen2 > 0;
    }

    private maleCaculate(): number {
        const abdomenAverage = this.getAbdomenAverage();
        return round(0.31457 * abdomenAverage - 0.10969 * this.weight + 10.8336);
    }

    private femaleCaculate(): number {        
        const abdomenAverage = this.getAbdomenAverage();
        const { height } = this.anthropometricCalculate;
        return round(0.11077 * abdomenAverage - 0.17666 * height + 0.14354 * this.weight + 51.03301);
    }

    private getBodyComposition(fatMassPercentage: number) : BodyComposition {  
        let bodyCompositionResult = this.getBodyCompositionResults(fatMassPercentage);        
        const { leanMassPercentage, leanMass, fatMass } = bodyCompositionResult;
        const { data: bodyComposition } = this.anthropometricCalculate;

        let bodyCompositionTableResult = [
            ...this.getLeanMassBodyCompositionResult(leanMassPercentage, leanMass), 
            ...this.getFatMassBodyCompositionResult(fatMassPercentage, fatMass)
        ] as BodyCompositionItemResult[]

        if(this.isWeigthAndHeight()) {
            bodyCompositionTableResult.push(this.bmiBodyResult);
        }

        bodyCompositionResult.results = bodyCompositionTableResult;

        return {
            ...bodyComposition,
            bmi: this.bmiBodyResult?.value,
            bodyCompositionResult: bodyCompositionResult
        } as BodyComposition;
    }

    private getDefaultBodyComposition() : BodyComposition { 
        let bodyCompositionTableResult = [];
        const { data: bodyComposition } = this.anthropometricCalculate;

        if(this.isWeigthAndHeight()) {
            bodyCompositionTableResult.push(this.bmiBodyResult);
        }

        return {
            ...bodyComposition,
            bmi: this.bmiBodyResult?.value,
            bodyCompositionResult: {
                id: this.anthropometricCalculate.data.bodyCompositionResult.id,
                results: bodyCompositionTableResult
            }
        } as BodyComposition;
    }

    private getAbdomenAverage(): number {
        const { weltmanProtocol } = this.anthropometricCalculate.data;
        if(weltmanProtocol === undefined) return 0;
        const { abdomen1, abdomen2 } = weltmanProtocol;
        return (Number(abdomen1) + Number(abdomen2)) / 2;
    }
}