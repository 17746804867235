import React, { createContext, useCallback, useContext, useState } from 'react';
import ToastContainer from '../../components/utils/AppToast/AppToast';
import { v4 as uuidv4 } from 'uuid';



interface Props {
  children: React.ReactElement
}

interface AppToastContextData {
  addToast(messages: Omit<AppToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

export interface AppToastMessage {
  id: string;
  type?: 'success' | 'info' | 'error';
  title: string;
  description?: string;
}

const AppToastContext = createContext<AppToastContextData>({} as AppToastContextData);

export const ToastProvider: React.FC<Props> = ({ children } : Props ) => {

  const [messages, setMessages] = useState<AppToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description }: Omit<AppToastMessage, 'id'>) => {
      const id = uuidv4();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages(state => [...state, toast]);
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages(oldMessages =>
      oldMessages.filter(message => message.id !== id),
    );
  }, []);

  return (
    <AppToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages = {messages} />
    </AppToastContext.Provider>
  );
};

export function useAppToast(): AppToastContextData {
  const context = useContext(AppToastContext);
  if (!context) {
    throw new Error('useToast must be used within an AppToastContextProvider.');
  }
  return context;
}