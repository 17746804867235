import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Box, Alert, Grid, Divider } from '@mui/material';
import * as Yup from 'yup';
import LoginIcon from '@mui/icons-material/Login';
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import pessoaSentada from '../../assets/cadastro-usuario.svg';
import AppFormContainer from '../../components/layout/AppFormContainer';
import AppButton from '../../components/forms/AppButton';
import api from '../../services/api';
import AppLoading from '../../components/utils/AppLoading';
import AppErrorList from '../../components/forms/AppErrorList';
import { ROUTES } from '../../constants/routes';
import AppMaskedTextField from '../../components/forms/AppMaskedTextField';
import AppTextField from '../../components/forms/AppTextField';
import FormService, { FormErrors } from '../../services/form.service';


const formSchema = Yup.object({
  name: Yup
    .string()
    .required('Obrigatório informar o nome.'),
  password: Yup
    .string()
    .required('Obrigatório informar a senha.')
    .min(6, "A senha deve ter 6 ou mais dígitos.")
    .max(100, "A senha pode conter até 100 caracteres."),
  confirmationPassword: Yup
    .string()
    .oneOf([Yup.ref('password')],'Confirmação de senha inválida.'),
  email: Yup
    .string()
    .email("O e-mail deve ser válido.")
    .required('Obrigatório informar o email.'),
  cpf: Yup
    .string()
    .required("Obrigatório informar o CPF."),
  phone: Yup
    .string()
    .required("Obrigatório informar o telefone."),
});

const initialData = {
  name: '',
  cpf: '',
  phone: '',
  email: '',
  password: '',
  confirmationPassword: ''
};


const RegisterPage: React.FC = () => {

  const navigate = useNavigate();
  const { addToast } = useAppToast();

  const [errors, setErrors] = useState([] as string[]);
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [pageOrientation, setPageOrientation] = useState("Realize seu cadastro para acessar todas as funcionalidades do sistema!");
  const [btnRegisterName, setBtnRegisterName] = useState("Criar minha conta");


  const formService = new FormService(formData, setFormData, setErrors, setFormErrors);


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id: name, value } = event.target;
    formService.setInputValue(name, value);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    
    event.preventDefault();
    setIsLoading(true);
    formService.cleanErrors();
    
    try {

      await formSchema.validate(formData, {abortEarly: false});

      let copyFormData: any = { ...formData };

      copyFormData.document = {
        value: formData.cpf,
        type: 1
      }

      delete copyFormData.cpf;

      const response = await api.post(`/users/register`, copyFormData);

      if (response.status !== 201){
        throw response.data;
      }

      addToast({
        title: 'E-mail enviado com sucesso!',
        description: 'Enviamos um e-mail de confirmação. Confira a sua caixa de entrada.',
        type: 'success',
      });

      navigate(ROUTES.REGISTER_SUCCESS);

    } catch (err) {
      formService.handleErros(err);
    }

    setIsLoading(false);
  };


  useEffect(() => {

    const email = searchParams.get("email") ?? "";
    const name = searchParams.get("name") ?? "";
    setFormData({ ...initialData, email, name });
    setPageOrientation("Preencha os dados para concluir seu cadastro.");
    setBtnRegisterName("Continuar cadastro");

  }, [searchParams]);

  return (
    <>
      <AppLoading isLoading={isLoading} />
      <AppFormContainer
        title='Cadastre-se!'
        pagePicture={pessoaSentada}
        pageOrientation={pageOrientation}
      >

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{mt:1}}  alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <AppTextField
                id="name"
                fullWidth
                label="Nome completo*"
                name="name"
                value={formData.name}
                onChange={handleChange}
                errorMessage={formErrors.name}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <AppTextField
                id="email"
                fullWidth
                label="E-mail*"
                name="email"
                value={formData.email}
                onChange={handleChange}
                errorMessage={formErrors.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AppMaskedTextField
                id="cpf"
                fullWidth
                mask="999.999.999-99"
                label="CPF*"
                name="cpf"
                value={formData.cpf}
                onChange={handleChange}
                errorMessage={formErrors.cpf}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AppMaskedTextField
                id="phone"
                fullWidth
                mask="(99) 99999-9999"
                label="Telefone*"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                errorMessage={formErrors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <AppTextField
                  id="password"
                  fullWidth
                  label="Senha*"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  errorMessage={formErrors.password}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AppTextField
                id="confirmationPassword"
                fullWidth
                label="Confirme sua senha*"
                name="confirmationPassword"
                type="password"
                value={formData.confirmationPassword}
                onChange={handleChange}
                errorMessage={formErrors.confirmationPassword}
              />
            </Grid>

          </Grid>

          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "12px", textAlign: "justify" }}>
              Ao clicar em "Continuar cadastro", você concorda com os Termos de Uso e a Política de Privacidade da Nutrifit.
            </Typography>
          </Box>

          <AppErrorList errors={errors} />

          <AppButton
            endIcon={<LoginIcon />}
            type="submit"
            fullWidth
            variant='contained'
            label={btnRegisterName}
          />

          <Box sx={{ mt: 2 }}>
            <Alert severity="info" sx={{ textAlign: 'justify' }}>
              Nos comprometemos a jamais compartilhar suas informações com terceiros. Nossos dados são armazenados e protegidos de acordo com a 
              Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018). Você pode nos solicitar a exclusão de seus dados pessoais a qualquer momento.
            </Alert>
          </Box>
        </form>
        <Divider sx={{ my: 2 }}>Ou</Divider>
        <Box sx={{display:"flex", justifyContent:"center"}}>
          <Link style={{color: "#00000099"}}  to="/login">Clique aqui para fazer login</Link>
        </Box>

      </AppFormContainer>
    </>
  );
}

export default RegisterPage;