import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";

interface AppRichTextProps {
  text: string;
  setText: (value: string) => void,
  hasImage?: boolean;
}

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue="" onChange={(e) => e.persist()}>
      <option value="1">Título 1</option>
      <option value="2">Título 2</option>
      <option value="3">Título 3</option>
      <option value="4">Título 4</option>
      <option value="5">Título 5</option>
      <option value="6">Título 6</option>
      <option value="">Normal</option>
    </select>

    <select className="ql-font" defaultValue="sans-serif">
      <option value="sans-serif">Sans Serif</option>
      <option value="serif">Serifada</option>
      <option value="monospace">Monoespaçada</option>
    </select>

    <button className="ql-bold" title="Negrito"></button>
    <button className="ql-italic" title="Itálico"></button>
    <button className="ql-underline" title="Sublinhado"></button>

    <span className="ql-formats">
      <select className="ql-color"></select>
      <select className="ql-background"></select>
    </span>

    <span className="ql-formats">
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-align"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-link"></button>
      <button className="ql-image"></button>
    </span>
  </div>
);

export default function AppRichText(props: AppRichTextProps) {

  const { hasImage, text, setText } = props;

  const modules = {
    toolbar: {
      container: "#toolbar", 
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align"
  ];

  const handleProcedureContentChange = (content: any) => {
    console.log(content);
    setText(content);
  };

  return (
    <>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={text}
        onChange={handleProcedureContentChange}
      />
    </>
  );
}