import { configureStore, Reducer } from '@reduxjs/toolkit'
import layoutReducer, { LayoutState } from './reducers/layout.reducer';

export interface RootState {
  formValidation: any;
  layout: Reducer<LayoutState>;
}

export default configureStore({
  reducer: {
    layout: layoutReducer
  } as RootState
})