import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LockReset } from '@mui/icons-material';
import * as Yup from 'yup';
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import api from '../../services/api';
import AppLoading from '../../components/utils/AppLoading';
import AppErrorList from '../../components/forms/AppErrorList';
import AppButton from '../../components/forms/AppButton';
import AppFormContainer from '../../components/layout/AppFormContainer';
import { ROUTES } from '../../constants/routes';
import resetPassPicture from '../../assets/reset-senha.svg';
import FormService, { FormErrors } from '../../services/form.service';
import AppTextField from '../../components/forms/AppTextField';
import { Grid } from '@mui/material';


const schema = Yup.object({
  password: Yup
    .string()
    .required('Obrigatório informar a senha.'),
  confirmationPassword: Yup
    .string()
    .oneOf([Yup.ref('password')], 'Confirmação de senha inválida.'),
  email: Yup
    .string()
    .email("O e-mail deve ser válido.")
    .required('Obrigatório informar o email.')
});

const initialData = {
  password: "",
  confirmationPassword: "",
  email: "",
  token: ""
};

const ResetPassPage: React.FC = () => {

  const navigate = useNavigate();
  const { addToast } = useAppToast();
  const [searchParams] = useSearchParams();


  const [errors, setErrors] = useState([] as string[]);
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);


  const formService = new FormService(formData, setFormData, setErrors, setFormErrors);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id: name, value } = event.target;
    formService.setInputValue(name, value);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    formService.cleanErrors();

    try {
      await schema.validate(formData, { abortEarly: false });

      await api.post(`/users/reset-password`, formData);

      addToast({
        type: 'success',
        title: 'Senha alterada com sucesso!',
        description: 'Sua senha foi alterada com sucesso, faça o login novamente.',
      });

      navigate(ROUTES.LOGIN);
    } catch (err) {
      formService.handleErros(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const token = searchParams.get("token") ?? "";
    const email = searchParams.get("email") ?? "";
    setFormData((dados) => ({ ...dados, token, email }));
  }, [searchParams]);

  return (
    <>
      <AppLoading isLoading={isLoading} />
      <AppFormContainer
        title='Recupere sua senha!'
        pagePicture={resetPassPicture}
        pageOrientation='Altere sua senha para ter acesso ao sistema novamente.'
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={12}>
              <AppTextField
                id="email"
                label="Seu E-mail:*"
                name="email"
                fullWidth
                readOnly
                value={formData.email}
                onChange={handleChange}
                errorMessage={formErrors.email}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <AppTextField
                id="password"
                label="Sua senha:*"
                name="password"
                type="password"
                fullWidth
                autoFocus
                value={formData.password}
                onChange={handleChange}
                errorMessage={formErrors.password}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <AppTextField
                id="confirmationPassword"
                label="Confirme sua senha:*"
                name="confirmationPassword"
                type="password"
                fullWidth
                value={formData.confirmationPassword}
                onChange={handleChange}
                errorMessage={formErrors.confirmationPassword}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <AppButton
                endIcon={<LockReset />}
                type="submit"
                fullWidth
                variant='contained'
                label='Alterar senha'
              />
            </Grid>
          </Grid>
        </form>
        <AppErrorList errors={errors} />
      </AppFormContainer>
    </>
  );
}

export default ResetPassPage;
