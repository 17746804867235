import { FC, useCallback, useEffect, useState } from 'react';
import AppAcordion from '../../../components/layout/AppAcordion';
import AddIcon from '@mui/icons-material/Add';
import AppFileMedicalIcon from '../../../components/icons/AppFileMedicalIcon';
import { Box, TablePagination, Typography } from '@mui/material';
import CardInfo, { CardInfoMenu } from '../components/CardInfo';
import AppButton from '../../../components/forms/AppButton';
import { useNavigate } from 'react-router-dom';
import api from "../../../services/api";
import { AnamnesisData } from '../Stores/AnamnesisStore/types.d';
import { useDialog } from '../../../hooks/core/AppDialog/AppDialogProvider';
import { useAppToast } from '../../../hooks/core/AppToastContextProvider';
import { Delete, Edit, PictureAsPdf, Print } from '@mui/icons-material';

export interface LastAnamnesisSectionProps {
  patientId: string;
}

const LastAnamnesisSection: FC<LastAnamnesisSectionProps> = ({ patientId }) => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { addToast } = useAppToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anamnesisList, setAnamnesisList] = useState([] as AnamnesisData[]);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10],
    count: 0
  });

  const handleNavigate = useCallback((link: string, data: any) => {
    navigate(link || "", {
      state: data
    })
  }, [navigate]);

  const getAnamnesis = useCallback(async (patientId: string, rowsPerPage: number, page: number) => {
    const offset = page * rowsPerPage;
    setAnamnesisList([]);

    setIsLoading(true);
    const response = await api.get(`/anamnesis/patients/${patientId}?Limit=${rowsPerPage}&Offset=${offset}`)

    if (response.status === 200) {
      var { items, count } = response.data;
      const list = items as AnamnesisData[];

      setPagination(p => ({ ...p, count }))
      setAnamnesisList(list);
    }
    setIsLoading(false);
  }, []);

  const handleRemove = useCallback(async (patientId: string, anamnesisId: string) => {
    let confirm = await dialog.confirm({ title: "Confirma a exclusão desta anamnese?", message: "", invert: false });
    const { rowsPerPage, page } = pagination;
    if (!confirm) return;

    setIsLoading(true);

    const response = await api.delete(`/anamnesis/${anamnesisId}?patientId=${patientId}`);

    setIsLoading(false);

    if (response.status === 200) {
      addToast({
        title: "Anamnese excluida!",
        description: "Anamnese excluída com sucesso!",
        type: "success"
      });

      await getAnamnesis(patientId, rowsPerPage, page);
      return;
    }

    addToast({
      title: "Falha na exclusão da antropometria!",
      description: "Houve uma falha na exclusão da antropometria!",
      type: "error"
    });

  }, [addToast, getAnamnesis, pagination, dialog]);

  const handleChangePage = (event: unknown, newPage: number) => {

    if (!setPagination) return;

    setPagination((p) => ({
      ...p,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setPagination) return;

    setPagination((p) => ({
      ...p,
      rowsPerPage: parseInt(event.target.value)
    }));
  };

  const menus = useCallback((patientId: string, anamnese: AnamnesisData): CardInfoMenu[] => {
    const link = `/meus-pacientes/${patientId}/anamnese/${anamnese.id}`;
    return [
      {
        label: "Editar",
        icon: <Edit />,
        onClick: () => handleNavigate(link, anamnese)
      },
      {
        label: "Excluir",
        icon: <Delete />,
        onClick: () => handleRemove(patientId, anamnese.id!)
      },
      {
        label: "Imprimir",
        icon: <Print />,
        onClick: () => handleNavigate(link, anamnese)
      },
      {
        label: "PDF",
        icon: <PictureAsPdf />,
        onClick: () => handleNavigate(link, anamnese)
      }
    ];
  }, [handleNavigate, handleRemove]);

  useEffect(() => {
    getAnamnesis(patientId, pagination.rowsPerPage, pagination.page);
  }, [patientId, pagination.rowsPerPage, pagination.page]);

  return (<AppAcordion
    defaultExpanded={true}
    title="Últimas Anamneses"
    sx={{ mt: 3, fontSize: "12px" }}
    titleIcon={<AppFileMedicalIcon sx={{ fontSize: "22px", mr: 1 }} />}>
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1, p: "16px",
      borderRadius: "12px",
      backgroundColor: "#F2F2F2"
    }}>
      {!isLoading && anamnesisList.map(
        anamnese => {
          return <CardInfo
            link={`/meus-pacientes/${patientId}/anamnese/${anamnese.id}`}
            data={anamnese}
            key={anamnese.id}
            title={`Anamnese realizada dia ${new Date(anamnese.creationDate).toLocaleString()}`}
            icon={<AppFileMedicalIcon />}
            sx={{ fontSize: "18px", mr: 1 }}
            menus={menus(patientId, anamnese)}  />
        })
      }

      {(!isLoading && anamnesisList.length > 0) && <>
        <TablePagination
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
          }}

        />

      </>}

      {(!isLoading && anamnesisList.length === 0) && <>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

          <Typography variant='h6' sx={{ color: "text.secondary", mb: 2, fontWeight: "bold" }}>Nenhuma anamnese criada.</Typography>
          <AppButton label="Criar Nova Anamnese" variant="contained" startIcon={<AddIcon />} onClick={() => navigate(`/meus-pacientes/${patientId}/nova-anamnese`)} />

        </Box>

      </>}

      {isLoading && <>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

          <Typography variant="body1">Carregando...</Typography>

        </Box>
      </>}


    </Box>
  </AppAcordion>);
}

export default LastAnamnesisSection;